var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { START_FETCH_STATUS } from '@/shared/api/constants/startFetch.status';
var initialState = {
    status: {},
    tickers: {},
    selectedTicker: {},
    charts: {},
};
var getFirstTicker = createAsyncThunk('plansSlice/getFirstTicker', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.get('company-data/tickers/?offset=0&limit=1')];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, { data: resp.data, id: payload.id }];
            case 2:
                e_1 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_1)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var getCharts = createAsyncThunk('plansSlice/getCharts', function (_a, thunkAPI) {
    var id = _a.id, ticker = _a.ticker, candle_length = _a.candle_length, candle_format = _a.candle_format, values_count = _a.values_count, start_date = _a.start_date, end_date = _a.end_date;
    return __awaiter(void 0, void 0, void 0, function () {
        var resp, e_2;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, thunkAPI.extra.api.post('/candles/formatted/', { ticker: ticker, candle_length: candle_length, candle_format: candle_format, values_count: values_count, start_date: start_date, end_date: end_date })];
                case 1:
                    resp = _b.sent();
                    return [2 /*return*/, { id: id, data: resp.data }];
                case 2:
                    e_2 = _b.sent();
                    return [2 /*return*/, thunkAPI.rejectWithValue(e_2)];
                case 3: return [2 /*return*/];
            }
        });
    });
});
var getCompanyDataTickers = createAsyncThunk('plansSlice/getCompanyDataTickers', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var queryParams, resp, e_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                queryParams = [
                    "limit=".concat(payload.limit),
                    "offset=".concat(payload.offset),
                    payload.asset_type || payload.asset_type === 0 ? "asset_type=".concat(payload.asset_type) : '',
                    payload.ticker_type || payload.ticker_type === 0 ? "ticker_type=".concat(payload.ticker_type) : '',
                    payload.filter_query ? "filter_query=".concat(payload.filter_query) : '',
                    payload.exchange_name ? "exchange_name=".concat(payload.exchange_name) : '',
                ].filter(Boolean).join('&');
                return [4 /*yield*/, thunkAPI.extra.api.get("company-data/tickers/?".concat(queryParams))];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_3 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_3)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var getDataTicker = createAsyncThunk('plansSlice/getDataTicker', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var queryParams, resp, e_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                queryParams = [
                    'limit=1',
                    'offset=0',
                    "filter_query=".concat(payload.filter_query),
                    "exchange_name=".concat(payload.exchange_name),
                ].filter(Boolean).join('&');
                return [4 /*yield*/, thunkAPI.extra.api.get("company-data/tickers/?".concat(queryParams))];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_4 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_4)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var tickerChartSlice = createSlice({
    name: 'tickerChartSlice',
    initialState: initialState,
    reducers: {
    // setSelectedTicker(state, action) {
    //   const { id, ticker } = action.payload;
    //   state.selectedTicker[id] = ticker;
    // },
    },
    extraReducers: function (builder) {
        builder.addCase(getFirstTicker.pending, function (state, action) {
            var id = action.meta.arg.id;
            state.status[id] = START_FETCH_STATUS;
        });
        builder.addCase(getFirstTicker.rejected, function (state, action) {
            var id = action.meta.arg.id;
            state.status[id] = { isLoading: false, error: action.payload };
        });
        builder.addCase(getFirstTicker.fulfilled, function (state, action) {
            var id = action.meta.arg.id;
            state.status[id] = __assign(__assign({}, state.status[id]), { isLoading: false });
            state.selectedTicker[id] = action.payload.data.tickers[0];
        });
        builder.addCase(getCharts.pending, function (state, action) {
            var id = action.meta.arg.id;
            state.status[id] = START_FETCH_STATUS;
        });
        builder.addCase(getCharts.rejected, function (state, action) {
            var id = action.meta.arg.id;
            state.status[id] = { isLoading: false, error: action.payload };
            state.charts[id] = null;
        });
        builder.addCase(getCharts.fulfilled, function (state, action) {
            var id = action.meta.arg.id;
            state.status[id] = __assign(__assign({}, state.status[id]), { isLoading: false });
            state.charts[id] = action.payload.data;
        });
    },
});
export default tickerChartSlice.reducer;
// export const { removeTickerData } = tickerChartSlice.actions;
export { getFirstTicker, getCharts };
