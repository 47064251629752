import { styled } from '@/app/styles/mainStyles';
export var ToggleContainer = styled('button', {
    width: '40px',
    height: '24px',
    backgroundColor: '$controlsBgSecondaryDefault',
    borderRadius: '24px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease',
    padding: 0,
    border: 'none',
    '&.checked': {
        backgroundColor: '$controlsBgAccentDefault',
        '&:hover': {
            backgroundColor: '$controlsBgAccentDefault',
        },
    },
    '&.enabled': {
        backgroundColor: '$controlsBgAccentDefault',
        opacity: 0.6,
        '&:hover': {
            backgroundColor: '$controlsBgAccentDefault',
            cursor: 'not-allowed',
        },
    },
    variants: {
        disabled: {
            true: {
                cursor: 'not-allowed',
            },
        },
    },
});
export var ToggleSwitch = styled('div', {
    width: '20px',
    height: '20px',
    backgroundColor: '$alwaysWhite',
    borderRadius: '100%',
    margin: '2px',
    transition: 'transform 0.3s ease',
    '&.checked': {
        transform: 'translateX(16px)',
    },
    '&.enabled': {
        transform: 'translateX(16px)',
    },
    variants: {
        disabled: {
            true: {
                opacity: '50%',
                cursor: 'not-allowed',
            },
        },
    },
});
