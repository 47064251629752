import { styled } from '@/app/styles/mainStyles';
import { ToastMessage } from '@/widgets/ui/Toast/ToastSuccess/style';
import { ReactComponent as Error } from '@/shared/assets/icons/errorRed.svg';
export var ToastErrorMessage = styled(ToastMessage, {
    display: 'flex',
    gap: '8px',
});
export var ErrorIcon = styled(Error, {
    flexShrink: 0,
});
