var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useRef } from 'react';
import { Dialog, Search, Tabs, Text, Space, Popover } from '@/shared/ui';
import { ReactComponent as Planet } from '@/shared/assets/icons/Planet.svg';
import { ReactComponent as Chevron } from '@/shared/assets/icons/Arrow.svg';
import { ReactComponent as NoData } from '@/shared/assets/icons/Nodata.svg';
import { ReactComponent as NoDataDark } from '@/shared/assets/icons/NodataDark.svg';
import { LineChart, Line, YAxis } from 'recharts';
import * as S from './style';
import { tabsData, exchanges } from '@/widgets/ui/Landing/SearchDialog/const';
import { getCompanyDataTickers, getFollowCompanyDataTickers } from '@/entities/companyData/model/companyDataSlice';
import { useTypedDispatch } from '@/shared/hooks/useTypedDispatch/useTypedDispatch';
import { useTypedSelector } from '@/shared/hooks/useTypesSelector/useTypesSelector';
export var SearchDialog = function (_a) {
    var open = _a.open, close = _a.close, onSelectTicker = _a.onSelectTicker, _b = _a.type, type = _b === void 0 ? 'default' : _b;
    var dispatch = useTypedDispatch();
    var _c = useState('all'), activeTab = _c[0], setActiveTab = _c[1];
    var _d = useState(''), searchQuery = _d[0], setSearchQuery = _d[1];
    var _e = useState(false), allSourceClicked = _e[0], setAllSourceClicked = _e[1];
    var _f = useState([]), tickers = _f[0], setTickers = _f[1];
    var _g = useState(''), filterQuery = _g[0], setFilterQuery = _g[1];
    var _h = useState(''), exchangeName = _h[0], setExchangeName = _h[1];
    var _j = useState(0), offset = _j[0], setOffset = _j[1];
    var _k = useState(true), hasMore = _k[0], setHasMore = _k[1];
    var currentTheme = useTypedSelector(function (state) { return state.themeReducer.theme; });
    var companyDataTickersStatus = useTypedSelector(function (state) { return state.companyDataReducer.companyDataTickersStatus; });
    var followCompanyDataTickersStatus = useTypedSelector(function (state) { return state.companyDataReducer.followCompanyDataTickersStatus; });
    var activeTabRef = useRef(activeTab);
    var filterQueryRef = useRef(filterQuery);
    var refObserver = useRef(null);
    var handleClickTab = function (tab) {
        setActiveTab(tab.id);
        activeTabRef.current = tab.id;
    };
    var handleRowClick = function (path) {
        if (type === 'widget') {
            onSelectTicker(path);
        }
        else {
            window.open("/ticker/".concat(path.exchange_name, "__").concat(path.name), '_blank');
        }
    };
    var getChartData = function (priceData) {
        return priceData.map(function (price, index) { return ({ price: price, index: index }); });
    };
    var getLineColor = function (priceData) {
        return priceData[0] > priceData[priceData.length - 1] ? '#D32E5F' : '#4CAF50';
    };
    var closeDialog = function () {
        setActiveTab('all');
        close();
    };
    useEffect(function () {
        if (!open)
            return;
        var fetchTickers = function () { return __awaiter(void 0, void 0, void 0, function () {
            var asset_type, ticker_type, crypto, response_1, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setTickers([]);
                        setOffset(0);
                        setHasMore(true);
                        switch (activeTab) {
                            case 'stocks':
                                asset_type = 0;
                                ticker_type = 0;
                                break;
                            case 'crypto':
                                asset_type = 2;
                                ticker_type = 0;
                                break;
                            case 'futures':
                                ticker_type = 1;
                                break;
                            case 'currencies':
                                asset_type = 1;
                                ticker_type = 0;
                                break;
                            default:
                                break;
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, dispatch(getCompanyDataTickers({
                                limit: 100,
                                offset: 0,
                                asset_type: asset_type,
                                ticker_type: ticker_type,
                                filter_query: filterQuery,
                                exchange_name: exchangeName,
                            })).unwrap()];
                    case 2:
                        response_1 = _a.sent();
                        if (activeTabRef.current === activeTab &&
                            filterQueryRef.current === filterQuery) {
                            setTickers(function (prev) { return __spreadArray(__spreadArray([], prev, true), response_1.tickers, true); });
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        console.error('Ошибка загрузки данных:', error_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        if (filterQuery === '' && offset === 0) {
            fetchTickers();
        }
        else {
            var timer_1 = setTimeout(function () {
                fetchTickers();
            }, 1000);
            return function () { return clearTimeout(timer_1); };
        }
    }, [activeTab, open, exchangeName, filterQuery, dispatch]);
    var loadMoreTickers = function () { return __awaiter(void 0, void 0, void 0, function () {
        var asset_type, ticker_type, crypto, response_2, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    switch (activeTab) {
                        case 'stocks':
                            asset_type = 0;
                            ticker_type = 0;
                            break;
                        case 'crypto':
                            asset_type = 2;
                            ticker_type = 0;
                            break;
                        case 'futures':
                            ticker_type = 1;
                            break;
                        case 'currencies':
                            asset_type = 1;
                            ticker_type = 0;
                            break;
                        default:
                            break;
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, dispatch(getFollowCompanyDataTickers({
                            limit: 100,
                            offset: offset + 100,
                            asset_type: asset_type,
                            ticker_type: ticker_type,
                            filter_query: filterQuery,
                            exchange_name: exchangeName,
                        })).unwrap()];
                case 2:
                    response_2 = _a.sent();
                    if (activeTabRef.current === activeTab &&
                        filterQueryRef.current === filterQuery) {
                        setTickers(function (prev) { return __spreadArray(__spreadArray([], prev, true), response_2.tickers, true); });
                        setOffset(function (prev) { return prev + 100; });
                    }
                    if (response_2.tickers.length < 100) {
                        setHasMore(false);
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_2 = _a.sent();
                    console.error('Ошибка подгрузки дополнительных тикеров:', error_2);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (open) {
            document.documentElement.style.overflow = 'hidden';
        }
        else {
            document.documentElement.style.overflow = '';
        }
        return function () {
            document.documentElement.style.overflow = '';
        };
    }, [open]);
    useEffect(function () {
        if (!refObserver.current)
            return;
        var observer = new IntersectionObserver(function (entries) {
            if (entries[0].isIntersecting) {
                loadMoreTickers();
            }
        });
        observer.observe(refObserver.current);
        return function () {
            if (refObserver.current) {
                observer.unobserve(refObserver.current);
            }
        };
    }, [refObserver, companyDataTickersStatus, tickers]);
    return (_jsxs(Dialog, __assign({ css: {
            width: '1092px',
            height: '820px',
            zIndex: '99999999',
            paddingBottom: '0px',
            background: '$bgSecondlayer',
        }, disableAutofocus: true, open: open, onOpenChange: function () {
            closeDialog();
            setAllSourceClicked(false);
        }, outside: false, disableHeader: true }, { children: [_jsxs(S.HeaderWrapper, { children: [_jsx(Search, { value: filterQuery, onChange: function (e) {
                            setFilterQuery(e.target.value);
                            filterQueryRef.current = e.target.value;
                        }, onClear: function () {
                            setFilterQuery('');
                            filterQueryRef.current = '';
                        } }), _jsx(Tabs, { active: activeTab, onClick: function (t) {
                            handleClickTab(t);
                            setExchangeName('');
                        }, tabs: tabsData, type: "chips", styleType: "chips" })] }), _jsxs(S.TableHeader, { children: [_jsxs(Space, { children: [_jsx(Text, __assign({ type: "header", css: { color: '$textTertiaryDisabled', width: '210px' } }, { children: "SYMBOL" })), _jsx(Text, __assign({ type: "header", css: { color: '$textTertiaryDisabled', width: '110px' } }, { children: "1M PERF." })), _jsx(Text, __assign({ type: "header", css: { color: '$textTertiaryDisabled', width: 'fit-content' } }, { children: "DESCRIPTION" }))] }), _jsxs(Popover, __assign({ open: allSourceClicked, side: "bottom", css: { width: 'fit-content' }, onChangeOpen: function () {
                            setAllSourceClicked(!allSourceClicked);
                        }, trigger: _jsxs(S.SourceButton, { children: [_jsxs(Space, __assign({ size: "default", align: "center" }, { children: [_jsx(Planet, { width: 16, height: 16, color: "#00A5BC" }), _jsx(Text, __assign({ type: "header", css: { color: '$aquamarine', width: 'max-content' } }, { children: exchangeName ? exchangeName : 'All sources' }))] })), _jsx(S.Chevron, { children: _jsx(Chevron, { fill: "#00A5BC" }) })] }) }, { children: [_jsx(S.ExchangeWrapper, __assign({ onClick: function () {
                                    setExchangeName('');
                                    setAllSourceClicked(false);
                                } }, { children: _jsx(S.ExchangeButton, { children: "All sources" }) })), exchanges === null || exchanges === void 0 ? void 0 : exchanges.map(function (exchange) { return (_jsxs(S.ExchangeWrapper, __assign({ onClick: function () {
                                    setExchangeName(exchange.exchange_name);
                                    setAllSourceClicked(false);
                                } }, { children: [_jsx(S.Logo, { src: exchange.exchange_image_url
                                            ? exchange.exchange_image_url
                                            : 'https://101ways.ru/russia/2016/img/2/6021.jpg', css: { width: '24px', height: '24px' } }), _jsx(S.ExchangeButton, { children: exchange.exchange_name })] }))); })] }))] }), companyDataTickersStatus.isLoading ? (_jsx(S.LoadingContainer, { children: _jsx(S.LoadingIconContainer, { children: _jsx(S.LoadingIcon, {}) }) })) : tickers && tickers.length > 0 ? (_jsxs(S.TickersContainer, { children: [tickers.map(function (ticker) { return (_jsxs(S.Row, { children: [_jsxs(S.LinkWrapper, __assign({ onClick: function () { return handleRowClick(ticker); } }, { children: [_jsxs(Space, __assign({ size: "medium", align: "center", css: { width: '160px' } }, { children: [_jsx(S.Logo, { src: ticker.logo_url
                                                    ? ticker.logo_url
                                                    : 'https://101ways.ru/russia/2016/img/2/6021.jpg', css: { width: '32px', height: '32px' } }), _jsx(Text, __assign({ type: "primary", css: { fontWeight: 400 } }, { children: ticker.name }))] })), _jsx("div", __assign({ style: {
                                            display: 'flex',
                                            width: '60px',
                                            height: '60px',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        } }, { children: _jsxs(LineChart, __assign({ width: 60, height: 60, data: getChartData(ticker.prices_data) }, { children: [_jsx(YAxis, { domain: ['dataMin - 1000', 'dataMax + 1000'], hide: true }), _jsx(Line, { dataKey: "price", stroke: getLineColor(ticker.prices_data), dot: false, isAnimationActive: false })] })) })), _jsx(Text, __assign({ type: "primary", css: {
                                            width: '500px',
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            fontWeight: 400,
                                        } }, { children: ticker.description }))] })), _jsxs(Space, __assign({ size: "medium", align: "center" }, { children: [_jsx(Text, __assign({ type: "primary", css: { fontWeight: 400 } }, { children: ticker.exchange_name })), _jsx(S.Logo, { src: ticker.exchange_image_url
                                            ? ticker.exchange_image_url
                                            : 'https://101ways.ru/russia/2016/img/2/6021.jpg', css: { width: '32px', height: '32px' } })] }))] }, ticker.name)); }), !followCompanyDataTickersStatus.isLoading &&
                        tickers.length > 99 &&
                        hasMore && _jsx("div", { ref: refObserver, style: { height: '0px' } }), followCompanyDataTickersStatus.isLoading && (_jsx(S.LoadingContainer, __assign({ style: { height: '72px' } }, { children: _jsx(S.LoadingIconContainer, { children: _jsx(S.LoadingIcon, {}) }) })))] })) : (_jsxs(S.NoFoundContainer, { children: [currentTheme === 'dark' ? _jsx(NoDataDark, {}) : _jsx(NoData, {}), _jsx(S.NoFoundText, { children: "No results found" })] }))] })));
};
