import { styled } from '@/app/styles/mainStyles';
export var AvatarWithNameContainer = styled('div', {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
});
export var ImgContainer = styled('img', {
    borderRadius: '20px',
    width: '44px',
    height: '44px',
    objectFit: 'cover',
});
