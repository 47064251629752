import { styled } from '@/app/styles/mainStyles';
import { Text } from '@/shared/ui/Text/style';
export var ToastErrorContainerWrapper = {
    display: 'flex',
    padding: '12px 20px',
    borderRadius: '12px',
    background: '#1D2023',
    border: '1px solid #D32E5F',
};
export var ToastMessage = styled(Text, {
    fontFamily: '$montserrat',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '22px',
    color: '$alwaysWhite !important',
    '@mobile': {
        fontSize: '14px',
        lineHeight: '20px',
        width: '100%',
    },
});
