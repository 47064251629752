var _a;
import { styled } from '@/app/styles/mainStyles';
import { ReactComponent as Arrow } from '@/shared/assets/icons/Arrow.svg';
export var DropdownTrigger = styled('div', {
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '22px',
    color: '$primaryText',
    '&[data-state-open="true"]': {
        color: '$lightTextDefault',
    },
});
export var Dropdown = styled('div', {
    boxSizing: 'border-box',
    height: '80px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    padding: '12px',
    borderBottom: '2px solid transparent',
    cursor: 'pointer',
    transition: 'all .1s',
    variants: {
        active: {
            true: (_a = {
                    borderBottom: '2px solid $lightTextDefault'
                },
                _a["".concat(DropdownTrigger)] = {
                    color: '$lightTextDefault',
                },
                _a),
        },
    },
    '@mobile:': {
        height: '60px !important',
        padding: '0',
    },
});
export var DropdownContent = styled('div', {
    boxSizing: 'border-box',
    background: '$white',
    position: 'absolute',
    top: '70px',
    width: '189px',
    padding: '8px',
    left: '35%',
    visibility: 'hidden',
    opacity: 0,
    zIndex: '-999',
    transform: 'translateX(-50%)',
    boxShadow: '$modalsShadow',
    borderRadius: '8px',
    transition: 'all .2s',
    '&[data-state-open="true"]': {
        zIndex: '999999',
        visibility: 'visible',
        opacity: 1,
    },
});
export var ArrowIcon = styled(Arrow, {
    width: '24px',
    height: '24px',
    fill: '$primaryText',
    transition: 'transform .2s',
    '&[data-state-open="true"]': {
        fill: '$lightTextDefault',
        transform: 'rotate(-180deg)',
    },
    '&[data-state-active="true"]': {
        fill: '$lightTextDefault',
        transform: 'rotate(0deg)',
    },
});
