import { styled } from '@/app/styles/mainStyles';
export var CheckboxContainer = styled('div', {
    display: 'flex',
    border: '1px solid $controlsStrokeDefault',
    width: '20px',
    height: '20px',
    borderRadius: '4px',
    boxSizing: 'border-box',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    cursor: 'pointer',
    variants: {
        checked: {
            true: {
                backgroundColor: '$aquamarine',
                border: 'none',
            },
        },
    },
});
