var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as S from './style';
import { Navigation } from '@/features/ui/Navigation/Navigation';
import { HeaderProfile } from '@/features/ui/HeaderProfile/HeaderProfile';
import React, { useEffect, useState } from 'react';
import { useTypedSelector } from '@/shared/hooks/useTypesSelector/useTypesSelector';
import { getProfile } from '@/entities/user/model/userModelSlice';
import { useTypedDispatch } from '@/shared/hooks/useTypedDispatch/useTypedDispatch';
import { useHistory, useLocation } from 'react-router-dom';
import { RoutePath } from '@/shared/config/routeConfig/routeConfig';
import { Space } from '@/shared/ui';
import { SearchDialog } from '@/widgets/ui/Landing/SearchDialog/SearchDialog';
var Header = function () {
    var dispatch = useTypedDispatch();
    var history = useHistory();
    var location = useLocation();
    var path = location.pathname;
    var currentTheme = useTypedSelector(function (state) { return state.themeReducer.theme; });
    var _a = useTypedSelector(function (state) { return state.userReducer; }), userData = _a.userData, isAuth = _a.isAuth;
    var _b = useState(false), scrolling = _b[0], setScrolling = _b[1];
    var _c = useState(false), isMenuOpen = _c[0], setIsMenuOpen = _c[1];
    var _d = useState(false), searchOpen = _d[0], setSearchOpen = _d[1];
    var handleScroll = function () {
        if (window.scrollY > 40) {
            setScrolling(true);
        }
        else {
            setScrolling(false);
        }
    };
    var openMenu = function () {
        setIsMenuOpen(!isMenuOpen);
    };
    var closeMenus = function () {
        setIsMenuOpen(false);
    };
    useEffect(function () {
        if (isAuth) {
            dispatch(getProfile(''));
        }
    }, [isAuth, dispatch]);
    useEffect(function () {
        window.addEventListener('scroll', handleScroll);
        return function () { return window.removeEventListener('scroll', handleScroll); };
    }, []);
    useEffect(function () {
        var htmlElement = document.documentElement;
        if (isMenuOpen) {
            htmlElement.style.overflow = 'hidden';
        }
        else {
            htmlElement.style.overflow = 'auto';
        }
        return function () {
            htmlElement.style.overflow = 'auto';
        };
    }, [isMenuOpen]);
    var searchHotKey = function (setIsOpen) {
        var handleKeyDown = function (event) {
            if (event.repeat)
                return;
            var isMac = navigator.platform.toUpperCase().includes('MAC');
            if ((isMac && event.metaKey && event.key === 'k') || (!isMac && event.ctrlKey && event.key === 'k')) {
                event.preventDefault();
                setIsOpen(true);
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return function () {
            document.removeEventListener('keydown', handleKeyDown);
        };
    };
    useEffect(function () {
        return searchHotKey(setSearchOpen);
    }, []);
    if (path === '/') {
        return (_jsxs("div", { children: [_jsx(SearchDialog, { open: searchOpen, close: function () { return setSearchOpen(false); } }), _jsx(S.BurgerMenuOverlay, __assign({ isOpen: isMenuOpen, css: { background: currentTheme === 'dark' ? 'rgba(10, 10, 10, 0.8)' : '' } }, { children: _jsx(S.BurgerMenuContent, { children: _jsx(Navigation, { menu: isMenuOpen ? 'mobileMenu' : undefined, token: isAuth, onItemClick: closeMenus }) }) })), _jsx(S.HeaderWrapperWithoutAuth, __assign({ id: "header", scrolling: scrolling, isMenuOpen: isMenuOpen, css: { backgroundColor: isMenuOpen ? currentTheme === 'dark' ? 'rgba(10, 10, 10, 0.8) !important' : '' : '' } }, { children: _jsxs(S.HeaderContainer, { children: [_jsxs(Space, __assign({ align: "center", css: { gap: '16px' } }, { children: [_jsx(S.LogoContainer, { children: _jsxs("div", __assign({ onClick: function () {
                                                closeMenus();
                                                setTimeout(function () {
                                                    history.push(RoutePath.main);
                                                }, 250);
                                            } }, { children: [_jsx(S.DesktopLogo, {}), _jsx(S.MobileLogo, { fill: currentTheme === 'dark' ? 'white' : 'black' })] })) }), _jsxs(S.Search, __assign({ onClick: function () { return setSearchOpen(true); } }, { children: [_jsx(S.IconSearch, {}), _jsxs(S.SearchTextWrapper, { children: ["Search (", _jsx(S.ShortcutKey, { children: "\u2318" }), "K)"] })] }))] })), _jsxs(S.BurgerContainer, __assign({ onClick: openMenu, "aria-label": "Toggle menu" }, { children: [_jsx(S.Line1, { active: isMenuOpen, css: { backgroundColor: currentTheme === 'dark' ? 'white' : 'black' } }), _jsx(S.Line2, { active: isMenuOpen, css: { backgroundColor: currentTheme === 'dark' ? 'white' : 'black' } }), _jsx(S.Line3, { active: isMenuOpen, css: { backgroundColor: currentTheme === 'dark' ? 'white' : 'black' } })] })), _jsx(S.NavContainer, { children: _jsx(Navigation, { token: isAuth }) }), _jsx(S.ProfileContainer, { children: _jsx(HeaderProfile, { userData: userData, token: isAuth }) })] }) }))] }));
    }
    return (_jsxs(_Fragment, { children: [_jsx(SearchDialog, { open: searchOpen, close: function () { return setSearchOpen(false); } }), _jsx(S.BurgerMenuOverlay, __assign({ isOpen: isMenuOpen, css: { backgroundColor: currentTheme === 'dark' ? 'rgba(0, 0, 0, 0.7)' : '' } }, { children: _jsx(S.BurgerMenuContent, { children: _jsx(Navigation, { menu: isMenuOpen ? 'mobileMenu' : undefined, token: isAuth, onItemClick: closeMenus }) }) })), _jsx(S.HeaderWrapper, __assign({ id: "header", isMenuOpen: isMenuOpen, css: { backgroundColor: isMenuOpen ? currentTheme === 'dark' ? 'rgba(10, 10, 10, 0.8) !important' : '' : '' } }, { children: _jsxs(S.HeaderContainer, { children: [_jsxs(Space, __assign({ align: "center", css: { gap: '16px' } }, { children: [_jsx(S.LogoContainer, { children: _jsxs("div", __assign({ onClick: function () {
                                            closeMenus();
                                            setTimeout(function () {
                                                history.push(RoutePath.main);
                                            }, 250);
                                        } }, { children: [_jsx(S.DesktopLogo, {}), _jsx(S.MobileLogo, { fill: currentTheme === 'dark' ? 'white' : 'black' })] })) }), _jsxs(S.Search, __assign({ onClick: function () { return setSearchOpen(true); } }, { children: [_jsx(S.IconSearch, {}), _jsxs(S.SearchTextWrapper, { children: ["Search (", _jsx(S.ShortcutKey, { children: "\u2318" }), "K)"] })] }))] })), _jsxs(S.BurgerContainer, __assign({ onClick: openMenu, "aria-label": "Toggle menu" }, { children: [_jsx(S.Line1, { active: isMenuOpen, css: { backgroundColor: currentTheme === 'dark' ? 'white' : 'black' } }), _jsx(S.Line2, { active: isMenuOpen, css: { backgroundColor: currentTheme === 'dark' ? 'white' : 'black' } }), _jsx(S.Line3, { active: isMenuOpen, css: { backgroundColor: currentTheme === 'dark' ? 'white' : 'black' } })] })), _jsx(S.NavContainer, { children: _jsx(Navigation, { token: isAuth }) }), _jsx(S.ProfileContainer, { children: _jsx(HeaderProfile, { userData: userData, token: isAuth }) })] }) }))] }));
};
export { Header };
