var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useHistory } from 'react-router-dom';
import { ReactComponent as Telegram } from '@/shared/assets/icons/social/Telegram.svg';
import { ReactComponent as Youtube } from '@/shared/assets/icons/social/Youtube.svg';
import { getData } from './data';
import * as S from './style';
var Footer = function (_a) {
    var background = _a.background, customBackground = _a.customBackground, _b = _a.typeLogo, typeLogo = _b === void 0 ? 'default' : _b;
    var history = useHistory();
    var isMobile = window.matchMedia('(max-width: 1279px)').matches;
    var data = getData();
    var flattenedData = data.flat();
    var navigate = function (path) {
        history.push(path);
    };
    return (_jsx(S.MainFooterContainer, __assign({ background: background || 'gray', css: { background: customBackground ? customBackground : '' } }, { children: _jsx(S.FooterWrapper, { children: _jsxs(S.Footer, { children: [_jsxs(S.FooterTopRow, { children: [_jsx(S.FooterLogo, __assign({ onClick: function () { return history.push('/'); } }, { children: typeLogo === 'landing' ? (_jsx(_Fragment, { children: _jsx(S.DesktopLogoGreen, {}) })) : (_jsx(_Fragment, { children: _jsx(S.LogoIcon, {}) })) })), _jsx(S.FooterNav, { children: _jsx(S.FooterLinksWrapper, { children: (isMobile ? flattenedData : data).map(function (item, index) {
                                        if (Array.isArray(item)) {
                                            return (_jsx(S.FooterLinks, { children: item.map(function (child) { return (_jsx(S.FooterLink, __assign({ onClick: function () { return navigate(child.path); } }, { children: child.title }), child.id)); }) }, index));
                                        }
                                        return (_jsx(S.FooterLinks, { children: _jsx(S.FooterLink, __assign({ onClick: function () { return navigate(item.path); } }, { children: item.title }), item.id) }, index));
                                    }) }) })] }), _jsx(S.Divider, {}), _jsxs(S.FooterBottomRow, { children: [_jsxs(S.InfoTextWrapper, { children: [_jsx(S.BottomText, { children: "State of Delaware 16192, Coastal Highway, Lewes, Delaware 19958, County of Sussex" }), _jsx(S.BottomText, { children: "Azrieli Sarona Tower, Derech Menachem Begin 121, Tel Aviv, Israel" }), _jsx(S.CompanyDesktop, __assign({ css: { marginTop: '12px' } }, { children: "QATS inc. 2025" }))] }), _jsxs(S.FooterSocialMedia, { children: [_jsx(Telegram, { style: { color: '#2AABEE' } }), _jsx(Youtube, { style: { color: '#FF0000' }, width: 40, height: 28, fill: "white" })] }), _jsx(S.CompanyMobile, { children: "QATS inc. 2025" })] })] }) }) })));
};
export { Footer };
