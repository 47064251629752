import { styled } from '@/app/styles/mainStyles';
import { ReactComponent as Arrow } from '@/shared/assets/icons/Arrow.svg';
export var DropdownHeaderWrapper = styled('div', {
    display: 'flex',
    position: 'relative',
});
export var DropdownHeaderTrigger = styled('div', {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '12px 20px',
    cursor: 'pointer',
    transition: 'background .2s ease',
    borderRadius: '12px',
    '@mobile': {
        padding: '12px 0px',
    },
});
export var DropdownHeaderContent = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    background: '$bgSecondlayer',
    position: 'absolute',
    top: '60px',
    width: '256px',
    padding: '8px',
    right: '24px',
    visibility: 'hidden',
    opacity: 0,
    zIndex: '-999',
    boxShadow: '$modalsShadow',
    borderRadius: '8px',
    transition: 'all .2s',
    '&[data-state-open="true"]': {
        zIndex: '999999',
        visibility: 'visible',
        opacity: 1,
    },
    '@mobile': {
        display: 'none',
    },
});
export var Divider = styled('div', {
    background: '$divider',
    width: '100%',
    height: '1px',
    margin: '6px 0',
});
export var DropdownButton = styled('button', {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '8px',
    background: 'none',
    border: 'none',
    outline: 'none !important',
    padding: '8px 16px',
    fontFamily: 'Montserrat',
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '28px',
    color: '$primaryText',
    cursor: 'pointer',
    transition: 'background .2s ease',
    borderRadius: '8px',
    '&:hover': {
        background: '$controlsBgSecondaryDefault',
    },
});
export var DropdownArrow = styled('div', {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
        transition: 'transform .2s',
    },
    '&[data-state-open="true"] ': {
        '& svg': {
            transform: 'rotate(-180deg)',
        },
    },
});
export var ArrowIcon = styled(Arrow, {
    width: '24px',
    height: '24px',
    fill: '$primaryText',
    '@mobile': {
        display: 'none',
    },
});
