export var tabsData = [
    {
        id: 'all',
        value: 'All',
    },
    {
        id: 'stocks',
        value: 'Stocks',
    },
    {
        id: 'crypto',
        value: 'Crypto',
    },
    {
        id: 'currencies',
        value: 'Currencies',
    },
    {
        id: 'futures',
        value: 'Futures',
    },
];
export var exchanges = [
    {
        exchange_name: 'AMEX',
        exchange_image_url: 'https://news-images.qats.us/logo/AMEX.jpg',
    },
    {
        exchange_name: 'Binance',
        exchange_image_url: 'https://news-images.qats.us/logo/BinanceBlack2.jpg',
    },
    {
        exchange_name: 'Binance Futures',
        exchange_image_url: 'https://news-images.qats.us/logo/Binance Futures.jpg',
    },
    {
        exchange_name: 'NASDAQ',
        exchange_image_url: 'https://news-images.qats.us/logo/NASDAQ.jpg',
    },
    {
        exchange_name: 'NYSE',
        exchange_image_url: 'https://news-images.qats.us/logo/NYSE.jpg',
    },
];
