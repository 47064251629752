var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as S from './style';
export var Space = function (_a) {
    var children = _a.children, size = _a.size, align = _a.align, content = _a.content, direction = _a.direction, textAlign = _a.textAlign, css = _a.css, onClick = _a.onClick, grow = _a.grow;
    var handleClick = function () {
        if (onClick) {
            onClick();
        }
    };
    return (_jsx(S.Space, __assign({ onClick: handleClick, textAlign: textAlign, content: content, size: size, align: align, direction: direction, css: css, grow: grow }, { children: children })));
};
