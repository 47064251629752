var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { BorderType } from './types';
import { ReactComponent as Arrow } from '@/shared/assets/icons/Arrow2.svg';
import { ReactComponent as ArrowDown } from '@/shared/assets/icons/arrowDown.svg';
import { ReactComponent as Cross } from '@/shared/assets/icons/Cross2.svg';
import { MARGIN_TOP } from './data';
import * as S from './style';
import { Checkbox, Text, Tooltip } from '@/shared/ui';
var getType = function (error, type) {
    if (type === 'text')
        return type;
    if (error)
        return BorderType.ERROR;
    return BorderType.DEFAULT;
};
var Select = memo(function (_a) {
    var id = _a.id, isError = _a.isError, value = _a.value, onChange = _a.onChange, options = _a.options, placeholder = _a.placeholder, _b = _a.withoutBorder, withoutBorder = _b === void 0 ? false : _b, _c = _a.isLeft, isLeft = _c === void 0 ? false : _c, _d = _a.padding, padding = _d === void 0 ? 'small' : _d, css = _a.css, cssInput = _a.cssInput, cssDropdown = _a.cssDropdown, _e = _a.minWidth, minWidth = _e === void 0 ? 260 : _e, _f = _a.type, type = _f === void 0 ? 'default' : _f, _g = _a.logoType, logoType = _g === void 0 ? 'circle' : _g, _h = _a.isMulti, isMulti = _h === void 0 ? false : _h, _j = _a.isValid, isValid = _j === void 0 ? true : _j, _k = _a.disabled, disabled = _k === void 0 ? false : _k, disableArrow = _a.disableArrow, selectRef = _a.selectRef, noDataContent = _a.noDataContent, cssOption = _a.cssOption;
    var ref = useRef(null);
    var inputRef = useRef(null);
    var refDropdown = useRef(null);
    var _l = useState(false), isOpen = _l[0], setIsOpen = _l[1];
    var _m = useState(null), highlightedIndex = _m[0], setHighlightedIndex = _m[1];
    var _o = useState(true), isFirstInteraction = _o[0], setIsFirstInteraction = _o[1];
    var _p = useState(null), activeIndex = _p[0], setActiveIndex = _p[1];
    var _q = useState([]), selectOptions = _q[0], setSelectOptions = _q[1];
    var _r = useState({ top: 0, left: 0, width: 0 }), position = _r[0], setPosition = _r[1];
    var _s = useState(null), modalContainer = _s[0], setModalContainer = _s[1];
    var _t = useState([]), visibleItems = _t[0], setVisibleItems = _t[1];
    var _u = useState([]), hiddenItems = _u[0], setHiddenItems = _u[1];
    var _v = useState(0), hiddenCount = _v[0], setHiddenCount = _v[1];
    var _w = useState(''), text = _w[0], setText = _w[1];
    var _x = useState(''), logo = _x[0], setLogo = _x[1];
    var _y = useState(undefined), svg = _y[0], setSvg = _y[1];
    useEffect(function () {
        if (options === null || options === void 0 ? void 0 : options.length) {
            setSelectOptions(options);
        }
    }, [options]);
    var handlePosition = useCallback(function () {
        var _a, _b;
        if (ref.current) {
            var element = (_a = ref.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
            var input = (_b = ref.current) === null || _b === void 0 ? void 0 : _b.getBoundingClientRect();
            if (isLeft) {
                setPosition({
                    left: element.x + window.scrollX - (element.width > minWidth ? element.width : minWidth - input.width),
                    top: element.y + window.scrollY + element.height + MARGIN_TOP,
                    width: element.width > minWidth ? element.width : minWidth,
                });
            }
            else {
                setPosition({
                    left: element.x + window.scrollX,
                    top: element.y + window.scrollY + element.height + MARGIN_TOP,
                    width: element.width > minWidth ? element.width : minWidth,
                });
            }
        }
    }, [minWidth]);
    var checkValue = function (itemValue) {
        if (isMulti && Array.isArray(value)) {
            return !!value.find(function (val) { return val.value === itemValue; });
        }
        else {
            return itemValue === value.value;
        }
    };
    useLayoutEffect(function () {
        if (isOpen) {
            handlePosition();
        }
    }, [isOpen, handlePosition]);
    var handleClickOutside = useCallback(function (event) {
        var _a, _b;
        event.stopPropagation();
        if (!((_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.contains(event.target))
            && !((_b = refDropdown === null || refDropdown === void 0 ? void 0 : refDropdown.current) === null || _b === void 0 ? void 0 : _b.contains(event.target))) {
            setIsOpen(false);
        }
    }, [selectOptions]);
    var closeDropdown = useCallback(function () {
        setIsOpen(false);
    }, []);
    useEffect(function () {
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside, true);
            window.addEventListener('resize', closeDropdown, true);
        }
        return function () {
            document.removeEventListener('mousedown', handleClickOutside, true);
            window.removeEventListener('resize', closeDropdown, true);
        };
    }, [isOpen, handleClickOutside]);
    var changeValue = function (option) {
        onChange(option);
        setIsOpen(false);
    };
    var onChangeMulti = function (option) {
        if (isMulti && Array.isArray(value)) {
            var indexOption_1 = value.findIndex(function (item) { return item.value === option.value; });
            if (indexOption_1 === -1) {
                onChange(__spreadArray(__spreadArray([], value, true), [option], false));
            }
            else {
                onChange(value.filter(function (_, index) { return indexOption_1 !== index; }));
            }
        }
    };
    var removeMultiOption = function (option) {
        if (isMulti && Array.isArray(value)) {
            onChange(value.filter(function (item) { return item.value !== option.value; }));
        }
    };
    useEffect(function () {
        if (isOpen) {
            var initIndex = selectOptions.findIndex(function (i) { return i.value === value.value; });
            setHighlightedIndex(initIndex);
            setIsFirstInteraction(true);
        }
    }, [isOpen, selectOptions, value]);
    var scrollToActiveOption = function (index) {
        var dropdown = refDropdown.current;
        var optionElements = dropdown === null || dropdown === void 0 ? void 0 : dropdown.querySelectorAll('.dropdown-option');
        if (!dropdown || !optionElements || !optionElements[index])
            return;
        var activeOption = optionElements[index];
        var dropdownRect = dropdown.getBoundingClientRect();
        var optionRect = activeOption.getBoundingClientRect();
        if (optionRect.bottom > dropdownRect.bottom) {
            dropdown.scrollTop += optionRect.bottom - dropdownRect.bottom;
        }
        else if (optionRect.top < dropdownRect.top) {
            dropdown.scrollTop -= dropdownRect.top - optionRect.top;
        }
    };
    useEffect(function () {
        if ((value === null || value === void 0 ? void 0 : value.value) && refDropdown.current) {
            var selectedOptionIndex = selectOptions.findIndex(function (option) { return option.value === value.value; });
            if (selectedOptionIndex !== -1) {
                setActiveIndex(selectedOptionIndex);
                scrollToActiveOption(selectedOptionIndex);
            }
        }
    }, [value, selectOptions, isOpen]);
    var handleKeyDown = function (event) {
        if (event.key === 'ArrowDown') {
            event.preventDefault();
            if (!isOpen) {
                setIsOpen(true);
                return;
            }
            setHighlightedIndex(function (prevIndex) {
                var nextIndex = prevIndex === null || prevIndex >= selectOptions.length - 1
                    ? 0
                    : prevIndex + 1;
                scrollToActiveOption(nextIndex);
                return nextIndex;
            });
            setActiveIndex(function (prevIndex) {
                var nextIndex = prevIndex === null || prevIndex >= selectOptions.length - 1
                    ? 0
                    : prevIndex + 1;
                scrollToActiveOption(nextIndex);
                return nextIndex;
            });
        }
        if (event.key === 'ArrowUp') {
            event.preventDefault();
            if (!isOpen) {
                setIsOpen(true);
                return;
            }
            setHighlightedIndex(function (prevIndex) {
                var nextIndex = prevIndex === null || prevIndex <= 0
                    ? selectOptions.length - 1
                    : prevIndex - 1;
                scrollToActiveOption(nextIndex);
                return nextIndex;
            });
            setActiveIndex(function (prevIndex) {
                var nextIndex = prevIndex === null || prevIndex <= 0
                    ? selectOptions.length - 1
                    : prevIndex - 1;
                scrollToActiveOption(nextIndex);
                return nextIndex;
            });
        }
        if (event.key === 'Enter' && highlightedIndex !== null && highlightedIndex !== -1) {
            event.preventDefault();
            var selectedItem = selectOptions[highlightedIndex];
            changeValue(selectedItem);
            setIsOpen(false);
        }
        else if (event.key === 'Enter') {
            setIsOpen(false);
        }
        if (event.key === 'Tab') {
            setIsOpen(false);
        }
    };
    var openDropdown = function () {
        if (!disabled) {
            setIsOpen(true);
        }
        if ((refDropdown === null || refDropdown === void 0 ? void 0 : refDropdown.current) && activeIndex !== null) {
            scrollToActiveOption(activeIndex);
        }
    };
    var updateVisibleItems = useCallback(function () {
        var MAX_WIDTH = 350;
        if (inputRef.current && Array.isArray(value)) {
            var currentWidth_1 = 0;
            var visible_1 = [];
            var hidden_1 = [];
            var hiddenElements_1 = 0;
            var measureWidth_1 = function (item) {
                var element = document.createElement('div');
                element.style.display = 'inline-block';
                element.textContent = item.text;
                document.body.appendChild(element);
                var width = element.getBoundingClientRect().width;
                document.body.removeChild(element);
                return width;
            };
            value.forEach(function (item) {
                var itemWidth = measureWidth_1(item) + 21;
                if (currentWidth_1 + itemWidth <= MAX_WIDTH) {
                    visible_1.push(item);
                    currentWidth_1 += itemWidth;
                }
                else {
                    hiddenElements_1++;
                    hidden_1.push(item);
                }
            });
            var maxVisibleItems = 5;
            setVisibleItems(visible_1.slice(0, maxVisibleItems));
            setHiddenItems(hidden_1);
            setHiddenCount(hiddenElements_1);
        }
    }, [value]);
    useEffect(function () {
        updateVisibleItems();
    }, [value, updateVisibleItems]);
    useEffect(function () {
        var mainContainer = document.querySelector('.main-container');
        setModalContainer(mainContainer);
    }, []);
    useEffect(function () {
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside, true);
            window.addEventListener('resize', closeDropdown, true);
            window.addEventListener('scroll', handlePosition, true);
        }
        return function () {
            document.removeEventListener('mousedown', handleClickOutside, true);
            window.removeEventListener('resize', closeDropdown, true);
            window.removeEventListener('scroll', handlePosition, true);
        };
    }, [isOpen, handleClickOutside, handlePosition]);
    useEffect(function () {
        var _a, _b;
        if (!isMulti && !Array.isArray(value)) {
            var desc = value === null || value === void 0 ? void 0 : value.text;
            var image = value === null || value === void 0 ? void 0 : value.logo;
            var svg_1 = (_a = value === null || value === void 0 ? void 0 : value.svg) === null || _a === void 0 ? void 0 : _a.select;
            setText(desc);
            setLogo(image);
            setSvg(svg_1);
        }
        else if (Array.isArray(value)) {
            var desc = ((_b = value[0]) === null || _b === void 0 ? void 0 : _b.text) || '';
            setText(desc);
        }
    }, [isMulti, value]);
    var setRefs = function (el) {
        ref.current = el;
        if (selectRef) {
            if (typeof selectRef === 'function') {
                selectRef(el);
            }
            else if (selectRef.hasOwnProperty('current')) {
                selectRef.current = el;
            }
        }
    };
    return (_jsxs(S.SelectWrapper, __assign({ ref: setRefs, onKeyDown: handleKeyDown, onFocus: function () {
            if (!disabled) {
                setIsOpen(true);
            }
        }, tabIndex: 0, css: { pointerEvents: disabled ? 'none' : 'auto' } }, { children: [_jsxs(S.Select, __assign({ "data-state-disabled": disabled, css: css, withoutBorder: withoutBorder, onClick: openDropdown, "data-state-valid": isValid, type: getType(isError, type), isActive: isOpen }, { children: [_jsxs(S.SelectInput, __assign({ ref: inputRef, id: id, css: cssInput, padding: type === 'text' ? type : padding }, { children: [!isMulti && (_jsxs(_Fragment, { children: [logo && _jsx(S.Logo, { type: logoType, src: logo, css: { opacity: disabled ? '0.6' : '1' } }), svg && svg, text && _jsx(S.TextWrapper, { children: text })] })), isMulti && Array.isArray(value) && value.length > 0 && (visibleItems.map(function (item) {
                                var _a;
                                return (_jsxs(S.MultiText, __assign({ onClick: function (e) {
                                        removeMultiOption(item);
                                        e.stopPropagation();
                                    } }, { children: [item.logo && _jsx(S.Logo, { type: logoType, src: item.logo }), ((_a = item.svg) === null || _a === void 0 ? void 0 : _a.select) && item.svg.select, item.text, _jsx(Cross, { width: 16, height: 16 })] }), item.id));
                            })), !text && (_jsx(S.Placeholder, __assign({ "data-state-disabled": disabled }, { children: placeholder }))), hiddenCount > 0 && (_jsx(Tooltip, __assign({ content: hiddenItems.map(function (i) { return i.text; }).join(', ') }, { children: _jsxs(S.MultiText, { children: ["+", hiddenCount] }) })))] })), !disableArrow && (_jsx(S.SelectIcon, __assign({ padding: padding, "data-state-open": isOpen }, { children: type === 'text' ? _jsx(ArrowDown, {}) : _jsx(Arrow, {}) })))] })), isOpen && modalContainer && createPortal(_jsx(S.SelectDropdown, __assign({ ref: refDropdown, style: position, "data-state-open": isOpen, "data-state-left-position": isLeft, tabIndex: 0, css: cssDropdown }, { children: selectOptions.length > 0 ? (selectOptions.map(function (item, index) {
                    var _a;
                    return isMulti ? (_jsxs(S.MultiDropdownList, __assign({ onClick: function () { return onChangeMulti(item); } }, { children: [_jsx(Checkbox, { checked: checkValue(item.value) }), item.logo && _jsx(S.Logo, { type: logoType, src: item.logo }), _jsx("p", { children: item.text })] }), item.id)) : (_jsxs(S.SelectDropdownOption, __assign({ onClick: function () { return changeValue(item); }, "data-state-select": !Array.isArray(value) && item.value === (value === null || value === void 0 ? void 0 : value.value), "data-highlighted": index === highlightedIndex, className: "dropdown-option ".concat(index === activeIndex ? 'active' : ''), css: index === highlightedIndex
                            ? __assign(__assign({}, cssOption), { backgroundColor: '$controlsBgSecondaryDefault' }) : __assign({}, cssOption) }, { children: [item.logo && _jsx(S.Logo, { type: logoType, src: item.logo }), ((_a = item.svg) === null || _a === void 0 ? void 0 : _a.option) && item.svg.option, _jsx("p", { children: item.text })] }), item.id));
                })) : (_jsx(S.NoDataWrapper, { children: noDataContent || _jsx(Text, { children: "No options available" }) })) })), modalContainer)] })));
});
export { Select };
