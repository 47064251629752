var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as S from './style';
import { useLocation, useHistory, matchPath } from 'react-router-dom';
import { navItems, navItemsWithoutAuth, mobileNav, mobileNavWithoutAuth, mobileSettings, } from './NavItems';
import React, { Fragment, useState } from 'react';
import { Dropdown } from '@/shared/ui/Dropdown/Dropdown';
import { NavigationItem } from '@/features/ui/Navigation/ui/NavigationItem/NavigationItem';
import { ReactComponent as LogOut } from '@/shared/assets/icons/logout.svg';
import { Button, Input, Space } from '@/shared/ui';
import { ThemeToggle } from '@/shared/ui/ThemeToggle/ThemeToggle';
import { logout } from '@/entities/user/model/userModelSlice';
import { RoutePath } from '@/shared/config/routeConfig/routeConfig';
import { useTypedDispatch } from '@/shared/hooks/useTypedDispatch/useTypedDispatch';
import { SearchDialog } from '@/widgets/ui/Landing/SearchDialog/SearchDialog';
var Navigation = function (_a) {
    var token = _a.token, menu = _a.menu, onItemClick = _a.onItemClick;
    var _b = useState(false), searchOpen = _b[0], setSearchOpen = _b[1];
    var history = useHistory();
    var location = useLocation();
    var dispatch = useTypedDispatch();
    var checkActive = function (pathname, route, children) {
        if (pathname === '/' && route !== '/') {
            return false;
        }
        if (matchPath(pathname, { path: route, exact: true })) {
            return true;
        }
        if (children) {
            return children.some(function (child) {
                return matchPath(pathname, { path: child.route, exact: true });
            });
        }
        return false;
    };
    var onLogout = function () { return __awaiter(void 0, void 0, void 0, function () {
        var payload;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, dispatch(logout({}))];
                case 1:
                    payload = (_a.sent()).payload;
                    if (payload) {
                        history.push(RoutePath.main);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var handleLogIn = function () {
        sessionStorage.setItem('prevPath', window.location.pathname);
        history.push(RoutePath.login);
    };
    var renderNavItems = function (items) {
        return items.map(function (el) {
            var _a;
            return (_jsx(Fragment, { children: ((_a = el.children) === null || _a === void 0 ? void 0 : _a.length) ? (_jsx(Dropdown, __assign({ active: checkActive(location.pathname, el.route, el.children), text: el.text, cssContent: { background: '$bgSecondlayer' } }, { children: el.children.map(function (item) { return (_jsx(NavigationItem, { active: location.pathname === item.route, id: "/".concat(item.id), text: item.text, icon: item.icon }, item.id)); }) }))) : (_jsx(S.NavItem, __assign({ active: checkActive(location.pathname, el.route), onClick: function () { return history.push(el.route); } }, { children: _jsx(S.HeaderText, __assign({ type: 'header' }, { children: el.text })) }))) }, el.id));
        });
    };
    var renderMobileNavItems = function (navData) {
        return (navData.map(function (category) { return (_jsxs(S.ItemsBlock, { children: [category.title && (_jsx(S.MobileNavHeader, { children: category.title })), category.items.map(function (item) { return (_jsx(S.MobileNavItem, __assign({ onClick: function () {
                        onItemClick();
                        setTimeout(function () {
                            history.push(item.route);
                        }, 250);
                    } }, { children: _jsx(Space, __assign({ align: 'center', size: 'default' }, { children: _jsx(S.MobileNavText, { children: item.text }) })) }), item.id)); })] }, category.title)); }));
    };
    return (_jsxs(_Fragment, { children: [_jsx(SearchDialog, { open: searchOpen, close: function () { return setSearchOpen(false); } }), !menu ? (_jsx(S.NavContainer, { children: token ? renderNavItems(navItems) : renderNavItems(navItemsWithoutAuth) })) : (_jsxs("div", __assign({ style: { height: '90%' } }, { children: [menu === 'mobileMenu' && (_jsxs(_Fragment, { children: [_jsx(Input, { typeInput: "default", placeholder: "Search", iconInside: true, type: "text", onClick: function () {
                                    onItemClick === null || onItemClick === void 0 ? void 0 : onItemClick();
                                    setSearchOpen(true);
                                }, iconFilter: true, css: {
                                    height: '40px',
                                    borderRadius: '12px',
                                    fontSize: '14px',
                                    fontWeight: 400,
                                    width: '100%',
                                    marginTop: '24px',
                                    marginBottom: '40px',
                                    paddingLeft: '52px',
                                    border: '1px solid $controlsStrokeDefault',
                                }, searchIconCss: {
                                    top: '47%',
                                    width: '24px',
                                    height: '24px',
                                    marginLeft: '8px',
                                } }), _jsx(S.MobileMenuContainer, { children: token ? (_jsxs(_Fragment, { children: [renderMobileNavItems(mobileNav), _jsx(S.MobileNavText, __assign({ css: { marginTop: '32px' }, onClick: function () {
                                                history.push(RoutePath.settings);
                                                onItemClick();
                                            } }, { children: "Profile & settings" })), _jsxs(S.ThemeToggle, { children: [_jsx(S.MobileNavText, { children: "Theme" }), _jsx(ThemeToggle, {})] }), _jsx(S.MobileNavText, __assign({ css: { marginTop: '32px' }, onClick: function () {
                                                onLogout();
                                                onItemClick();
                                            } }, { children: "Log out" }))] })) : (_jsxs(_Fragment, { children: [renderMobileNavItems(mobileNavWithoutAuth), _jsx(S.MobileNavText, __assign({ css: { marginTop: '32px' }, onClick: function () {
                                                handleLogIn();
                                                onItemClick();
                                            } }, { children: "Sign in" })), _jsxs(S.ThemeToggle, { children: [_jsx(S.MobileNavText, { children: "Theme" }), _jsx(ThemeToggle, {})] })] })) })] })), menu === 'mobileSettings' && (_jsxs(S.MobileNavContainer, { children: [_jsx("div", { children: renderMobileNavItems(mobileSettings) }), _jsxs(Button, __assign({ css: {
                                    backgroundColor: '$controlsBgSecondaryDefault',
                                    color: '$primaryText',
                                    height: '56px',
                                    marginTop: '32px',
                                }, onClick: function () {
                                    onLogout();
                                    onItemClick();
                                } }, { children: [_jsx(LogOut, {}), "Sign out"] }))] }))] })))] }));
};
export { Navigation };
