var _a;
import { styled } from '@/app/styles/mainStyles';
export var SelectWrapper = styled('div', {
    position: 'relative',
    outline: 'none',
});
export var Placeholder = styled('span', {
    fontSize: '16px',
    color: '$textSecondary',
    '&[data-state-disabled="true"]': {
        color: '$textDisabled',
    },
});
export var SelectLabel = styled('label', {
    fontSize: '20px',
    fontWeight: '400',
    lineHeight: '28px',
    fontFamily: '$montserrat',
    color: '$textSecondary',
    position: 'absolute',
    transition: 'all 250ms',
    left: '16px',
    variants: {
        type: {
            placeholder: {
                top: '16px',
                display: 'block',
            },
            label: {
                fontSize: '12px',
                lineHeight: '16px',
                top: '8px',
            },
        },
        isSmall: {
            true: {
                top: '8px !important',
                left: '30px !important',
                fontSize: '16px !important',
                lineHeight: '22px !important',
            },
        },
        disabled: {
            true: {
                color: '$textTertiaryDisabled',
            },
            false: {
                color: '$textSecondary',
            },
        },
    },
});
export var Select = styled('div', {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    position: 'relative',
    boxSizing: 'border-box',
    cursor: 'pointer',
    borderRadius: '16px',
    background: '$bgInput',
    transition: 'border .2s',
    padding: '0 10px 0 0',
    color: '$primaryText',
    whiteSpace: 'nowrap',
    outline: 'none',
    variants: {
        isActive: {
            true: {
                border: '1px solid $controlsStrokeFocus !important',
            },
        },
        type: {
            default: {
                borderRadius: '16px',
                border: '1px solid transparent',
                '&:hover': {
                    border: '1px solid $controlsStrokeHover',
                },
            },
            error: {
                border: '1px solid $inputBorderError',
                '&:hover': {
                    border: '1px solid $inputBorderError',
                },
                '&:focus-within, &:active': {
                    border: '1px solid $inputBorderError',
                },
            },
            text: {
                background: 'none',
                justifyContent: 'center',
                padding: '0 8px 0 0',
                border: 'none',
            },
        },
        withoutBorder: {
            true: {
                border: '1px solid transparent',
            },
        },
    },
    '&[data-state-disabled="true"]': {
        color: '$textTertiaryDisabled',
        pointerEvents: 'none',
        opacity: '0.8',
        '&:hover': {
            border: '1px solid transparent',
        },
    },
    '&[data-state-valid="false"]': {
        border: '1px solid $inputBorderError',
    },
});
export var SelectInput = styled('div', {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '16px',
    width: '100%',
    height: '58px',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '22px',
    fontFamily: '$montserrat',
    gap: '8px',
    background: '$bgInput',
    outline: 'none',
    cursor: 'pointer',
    border: 'none',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    variants: {
        padding: {
            text: {
                width: 'auto',
                display: 'inline-flex',
                background: 'none',
                padding: '7px 0 7px 8px',
            },
            small: {
                padding: '6px 0 6px 10px',
            },
            default: (_a = {
                    padding: '16px',
                    fontSize: '20px'
                },
                _a["".concat(Placeholder)] = {
                    fontSize: '20px',
                },
                _a),
            large: {
                padding: '',
            },
        },
    },
});
export var TextWrapper = styled('div', {
    boxSizing: 'border-box',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
});
export var SelectIcon = styled('div', {
    display: 'inline-flex',
    alignItems: 'center',
    pointerEvents: 'none',
    variants: {
        padding: {
            text: {
                svg: {
                    width: 16,
                    height: 16,
                },
            },
            small: {
                svg: {
                    width: 16,
                    height: 16,
                },
            },
            default: {
                svg: {
                    width: 24,
                    height: 24,
                },
            },
            large: {
                svg: {
                    width: 24,
                    height: 24,
                },
            },
        },
    },
    '& svg': {
        transition: 'transform .3s',
    },
    '&[data-state-open="true"]': {
        '& svg': {
            transform: 'rotate(-180deg)',
        },
    },
});
export var SelectDropdown = styled('div', {
    pointerEvents: 'all',
    position: 'absolute',
    flexDirection: 'column',
    boxSizing: 'border-box',
    padding: '8px',
    top: 0,
    width: '100%',
    minWidth: '100px',
    background: '$bgSecondlayer',
    borderRadius: '8px',
    boxShadow: '$modalsShadow',
    overflowY: 'auto',
    zIndex: '9999999999',
    display: 'flex',
    maxHeight: '298px',
    '&[data-state-left-position="true"]': {
        right: 0,
    },
    '&::-webkit-scrollbar': {
        width: '15px',
        height: '15px',
    },
    '&::-webkit-scrollbar:horizontal': {
        boxShadow: '0 -1px 0 0 $divider',
    },
    '&::-webkit-scrollbar:vertical': {
        boxShadow: '-1px 0 0 0 $divider',
    },
    '&::-webkit-scrollbar-thumb': {
        border: '2px solid $divider',
        background: '$divider',
        borderRadius: '8px',
    },
});
export var SelectDropdownOption = styled('div', {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    borderRadius: '8px',
    padding: '8px 16px',
    gap: '8px',
    transition: 'background-color .2s',
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '28px',
    cursor: 'pointer',
    color: '$primaryText',
    '&[data-state-select="true"]': {
        backgroundColor: '$aquamarine',
        color: '$alwaysWhite',
    },
    '&:hover:not(&[data-state-select="true"])': {
        backgroundColor: '$controlsBgSecondaryDefault',
    },
});
export var MultiDropdownList = styled('div', {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    boxSizing: 'border-box',
    borderRadius: '8px',
    padding: '8px 16px',
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '28px',
    cursor: 'pointer',
    gap: '8px',
    color: '$primaryText',
});
export var MultiText = styled('div', {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    background: '$controlsBgSecondaryDefault',
    padding: '3px 6px',
    borderRadius: '8px',
    boxSizing: 'border-box  ',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
});
export var Logo = styled('img', {
    objectFit: 'cover',
    variants: {
        type: {
            circle: {
                width: '24px',
                height: '24px',
                borderRadius: 999,
            },
            triangle: {
                width: '27px',
                height: '20px',
                borderRadius: 4,
            },
        },
    },
});
export var NoDataWrapper = styled('div', {
    padding: '8px',
});
