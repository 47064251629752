var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import * as S from './style';
var Dropdown = function (props) {
    var children = props.children, _a = props.text, text = _a === void 0 ? '' : _a, trigger = props.trigger, disableChevron = props.disableChevron, _b = props.cssContent, cssContent = _b === void 0 ? {} : _b, _c = props.active, active = _c === void 0 ? false : _c, _d = props.openType, openType = _d === void 0 ? 'hover' : _d;
    var _e = useState(false), isOpen = _e[0], setIsOpen = _e[1];
    var dropdownRef = useRef(null);
    var onMouseOver = function () {
        setIsOpen(true);
    };
    var onMouseLeave = function () {
        setIsOpen(false);
    };
    var handleClickOutside = function (event) {
        var _a;
        if (dropdownRef.current && !((_a = dropdownRef.current) === null || _a === void 0 ? void 0 : _a.contains(event.target)) && isOpen) {
            setIsOpen(false);
        }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return (_jsxs(S.Dropdown, __assign({ active: active, ref: dropdownRef, onMouseOver: openType === 'hover' ? onMouseOver : undefined, onMouseLeave: openType === 'hover' ? onMouseLeave : undefined, onClick: openType === 'click' ? function () { return setIsOpen(true); } : undefined }, { children: [_jsxs(S.DropdownTrigger, __assign({ "data-state-open": isOpen }, { children: [trigger ? trigger : text, !disableChevron && (_jsx(S.ArrowIcon, { "data-state-open": isOpen, "data-state-active": active }))] })), _jsx(S.DropdownContent, __assign({ "data-state-open": isOpen, css: cssContent }, { children: children }))] })));
};
export { Dropdown };
