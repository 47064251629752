var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { AccordionRoot, AccordionItem, AccordionTrigger, AccordionContent, AccordionHeader, Chevron } from './style';
var Accordion = function (_a) {
    var items = _a.items, css = _a.css, cssTrigger = _a.cssTrigger;
    return (_jsx(AccordionRoot, __assign({ type: "single", collapsible: true, css: css }, { children: items.map(function (item) { return (_jsxs(AccordionItem, __assign({ value: item.id }, { children: [_jsx(AccordionHeader, { children: _jsxs(AccordionTrigger, __assign({ css: cssTrigger }, { children: [item.title, _jsx("div", __assign({ style: { display: 'flex', alignItems: 'center', justifyContent: 'center', width: '24px' } }, { children: _jsx(Chevron, {}) }))] })) }), _jsx(AccordionContent, { children: item.content })] }), item.id)); }) })));
};
export { Accordion };
