var _a, _b;
import { styled } from '@/app/styles/mainStyles';
import { Text } from '@/shared/ui/Text/style';
export var NavContainer = styled('div', {
    display: 'flex',
});
export var MobileNavContainer = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    color: '$primaryText',
    fontFamily: '$montserrat',
});
export var MobileMenuContainer = styled('div', {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    flexDirection: 'column',
    color: '$primaryText',
    fontFamily: '$montserrat',
    gap: '8px',
});
export var MobileNavItem = styled('div', {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
});
export var ItemsBlock = styled('div', {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    gap: '8px',
});
export var MobileNavText = styled('div', {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    padding: '12px 0px',
    textAlign: 'center',
});
export var MobileNavHeader = styled('div', {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: '$textTertiaryDisabled',
    marginBottom: '32px',
});
export var HeaderText = styled(Text, {
    transition: 'color .1s',
});
export var ThemeToggle = styled(Text, {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
});
export var NavItem = styled('div', {
    padding: '12px 16px',
    display: 'flex',
    height: '54px',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: '2px solid transparent',
    '&:hover': (_a = {
            cursor: 'pointer'
        },
        _a["".concat(HeaderText)] = {
            color: '$lightTextDefault',
        },
        _a),
    variants: {
        active: {
            true: (_b = {
                    borderBottom: '2px solid $lightTextDefault'
                },
                _b["".concat(HeaderText)] = {
                    color: '$lightTextDefault',
                },
                _b),
        },
    },
});
