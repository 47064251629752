import { styled } from '@/app/styles/mainStyles';
import { Button } from '@/shared/ui/Button/style';
import { ReactComponent as UserIcon } from '@/shared/assets/icons/UserIcon.svg';
import { ReactComponent as SignInIcon } from '@/shared/assets/icons/SignIn.svg';
export var HeaderProfileContainer = styled('div', {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    alignItems: 'center',
    position: 'relative',
    gap: '8px',
});
export var User = styled(UserIcon, {
    cursor: 'pointer',
    color: '$primaryText !important',
    '@mobile': {
        display: 'none',
    },
    '@tablet': {
        display: 'none',
    },
});
export var SignIn = styled(SignInIcon, {});
export var UserAction = styled('div', {
    display: 'flex',
    padding: '8px 16px',
    borderRadius: '8px',
    gap: '8px',
    alignItems: 'center',
    transition: 'background 0.15s',
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: 400,
    color: '$primaryText',
    '&:hover': {
        cursor: 'pointer',
        background: '$controlsBgSecondaryDefault',
    },
});
export var Divider = styled('div', {
    background: '$divider',
    width: '100%',
    height: '1px',
    margin: '6px 0',
});
export var StyledButton = styled(Button, {
    background: '$controlsBgPrimaryDefault',
    color: '$textInvertedWhite',
    fontSize: '16px',
    height: '44px',
    transition: 'background 0.2s',
    borderRadius: '12px',
    '@mobile': {
        width: '112px',
        height: '44px',
        fontSize: '14px',
        fontWeight: 500,
        padding: '12px 16px !important',
        color: '$textInvertedWhite',
    },
    '&:hover': {
        background: '$controlsBgPrimaryHover',
    },
});
export var CloseSettings = styled('div', {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '36px',
    height: '36px',
});
export var AvatarWithNameContainer = styled('div', {});
export var AvatarWrapper = styled('div', {
    '@mobile': {
        marginLeft: '27px',
    },
});
