import { styled } from '@/app/styles/mainStyles';
export var Button = styled('button', {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 20px',
    gap: '8px',
    borderRadius: '12px',
    cursor: 'pointer',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '19px',
    border: 'none',
    fontFamily: '$montserrat',
    color: '#1D2023',
    boxSizing: 'border-box',
    transition: 'background 0.1s',
    '@mobile': {
        padding: '16px 20px !important',
    },
    '&:focus': {
        outline: '2px solid $divider',
    },
    variants: {
        color: {
            dark: {
                backgroundColor: '$controlsBgPrimaryDefault',
                color: '$textInvertedWhite',
                padding: '12px 20px',
                fontSize: '16px',
                fontWeight: 500,
                '&:hover': {
                    background: '$controlsBgPrimaryHover',
                },
            },
            create: {
                padding: '12px 20px',
                background: '$lightTextDefault',
                color: '$white',
                fontSize: '20px',
                fontWeight: 500,
                '&:hover': {
                    background: '$lightControlsAccent',
                },
            },
            default: {
                background: '$controlsBgSecondaryDefault',
                color: '$primaryText',
                fontSize: '16px',
                border: '1px solid $border',
                '&:hover': {
                    backgroundColor: '$controlsBgSecondaryHover',
                },
            },
            danger: {
                background: '$deliveryBackground',
                color: '$black',
                '&:hover': {
                    background: '$deliveryBackground',
                    color: '$textError',
                    boxShadow: '0 5px 20px rgba(0, 0, 0, 0.2)',
                },
            },
            transparent: {
                border: '1px solid $controlsStroke',
                background: 'transparent',
                color: '$primaryText',
                borderRadius: '8px',
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '22px',
            },
        },
        disabled: {
            true: {
                background: '$disabledButton',
                color: '$textTertiaryDisabled',
                '&:hover': {
                    boxShadow: 'none',
                    cursor: 'not-allowed',
                    backgroundColor: '$disabledButton',
                },
            },
        },
        size: {
            large: {
                padding: '12px 20px',
                fontSize: '20px !important',
                lineHeight: '28px',
            },
            medium: {
                padding: '10px 20px',
                fontSize: '16px',
                lineHeight: '22px',
            },
            headToolbar: {
                padding: '7px 16px',
                fontSize: '16px',
                lineHeight: '22px',
            },
            small: {
                padding: '7px 8px',
                fontSize: '16px',
                lineHeight: '22px',
                borderRadius: '8px',
            },
        },
    },
});
