import { configureStore } from '@reduxjs/toolkit';
import { api } from '@/shared/api/api';
import workspaceStrategySlice from '@/entities/strategy/model/workspaceStrategy/workspaceStrategySlice';
import userModelSlice from '@/entities/user/model/userModelSlice';
import createWorkspaceSlice from '@/entities/createWorkspace/model/createWorkspaceSlice';
import newsSlice from '@/entities/news/model/newsSlice';
import strategyMarketSlice from '@/entities/strategyMarket/model/strategyMarket/strategyMarketSlice';
import strategyCardSlice from '@/entities/strategyCard/model/strategyCard/strategyCardSlice';
import trendsSlice from '@/entities/trends/trendsSlice';
import plansSlice from '@/entities/plans/model/plansSlice';
import companyDataSlice from '@/entities/companyData/model/companyDataSlice';
import partnershipSlice from '@/entities/partnership/partnershipSlice';
import runSlice from '@/entities/run/model/runSlice';
import backtestSlice from '@/entities/backtest/model/backtestSlice';
import notificationSlice from '@/entities/notifications/notificationSlice';
import themeSlice from '@/entities/theme/model/themeSlice';
import tickersSlice from '@/entities/tickers/model/tickersSlice';
import widgetsSlice from '@/entities/widgets/model/widgetsSlice';
import brokersSlice from '@/entities/brokers/brokersSlice';
import currensiesSlice from '@/entities/currensies/currensiesSlice';
import widgetMenuSlice from '@/entities/widgetMenu/widgetMenuSlice';
import tickerChartSlice from '@/widgets/ui/CreateWorkspace/WidgetManager/WidgetContents/TickerChart/model/tickerChartSlice';
import selectWithSettingsSlice from '@/shared/ui/SelectWithSettings/model/selectWithSettingsSlice';
var store = configureStore({
    reducer: {
        workspaceStrategyReducer: workspaceStrategySlice,
        createWorkspaceReducer: createWorkspaceSlice,
        userReducer: userModelSlice,
        newsReducer: newsSlice,
        trendsReducer: trendsSlice,
        plansReducer: plansSlice,
        companyDataReducer: companyDataSlice,
        partnershipReducer: partnershipSlice,
        strategyMarketReducer: strategyMarketSlice,
        strategyCardReducer: strategyCardSlice,
        notificationsReducer: notificationSlice,
        runReducer: runSlice,
        backtestReducer: backtestSlice,
        themeReducer: themeSlice,
        tickersReducer: tickersSlice,
        widgetsReducer: widgetsSlice,
        brokersReducer: brokersSlice,
        currenciesReducer: currensiesSlice,
        widgetMenuReducer: widgetMenuSlice,
        tickerChartReducer: tickerChartSlice,
        selectWithSettingsReducer: selectWithSettingsSlice,
    },
    middleware: function (getDefaultMiddleware) {
        return getDefaultMiddleware({
            serializableCheck: false,
            thunk: {
                extraArgument: {
                    api: api,
                },
            },
        });
    },
    devTools: process.env.NODE_ENV !== 'production',
});
export default store;
