var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { styled } from '@/app/styles/mainStyles';
import { ReactComponent as Logo } from '@/shared/assets/icons/Qats.svg';
import { ReactComponent as ShortLogo } from '@/shared/assets/icons/mobileLogo.svg';
import { ReactComponent as SearchIcon } from '@/shared/assets/icons/search16.svg';
export var HeaderWrapper = styled('header', {
    boxSizing: 'border-box',
    background: '$bgTransparent',
    borderBottom: '1px solid $divider',
    height: '80px',
    width: '100%',
    position: 'fixed',
    top: 0,
    zIndex: 999,
    transition: 'all 0.3s ease',
    backdropFilter: 'blur(10px)',
    '@mobile': {
        height: '60px',
    },
    variants: {
        isMenuOpen: {
            true: {
                backdropFilter: 'Blur(15px) !important',
                background: 'rgba(255, 255, 255, 0.7) !important',
            },
        },
    },
    '&:hover': {
        backdropFilter: 'blur(10px)',
        background: '$bgTransparent',
    },
});
export var HeaderWrapperWithoutAuth = styled('header', {
    boxSizing: 'border-box',
    height: '80px',
    position: 'fixed',
    width: '100%',
    top: 0,
    zIndex: 99998,
    transition: 'all 0.3s ease',
    variants: {
        scrolling: {
            true: {
                backdropFilter: 'blur(10px)',
                background: '$bgTransparent',
            },
        },
        isMenuOpen: {
            true: {
                backdropFilter: 'Blur(15px) !important',
                background: 'rgba(255, 255, 255, 0.7) !important',
            },
        },
    },
    '@mobile': {
        height: '60px',
    },
    '&:hover': {
        backdropFilter: 'blur(10px)',
        background: '$bgTransparent',
    },
});
export var HeaderContainer = styled('div', {
    display: 'flex',
    boxSizing: 'border-box',
    maxWidth: '1440px',
    margin: '0 auto',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 96px',
    height: '100%',
    '@mobile': {
        padding: '0 16px',
        alignItems: 'center',
    },
    '@tablet': {
        padding: '0 16px',
        alignItems: 'center',
    },
});
export var LogoContainer = styled('div', {
    display: 'flex',
    alignItems: 'center',
    padding: '24px 0',
    cursor: 'pointer',
});
export var Search = styled('div', {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '10px 16px',
    boxSizing: 'border-box',
    background: '$bgInput',
    border: '1px solid $controlsStrokeDefault',
    width: '200px',
    height: '36px',
    borderRadius: '8px',
    cursor: 'pointer',
    color: '$textSecondary',
    fontFamily: '$montserrat',
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 400,
    '@mobile': {
        display: 'none',
    },
    '@tablet': {
        display: 'none',
    },
});
export var IconSearch = styled(SearchIcon, {});
export var ShortcutKey = styled('span', {
    fontSize: '13px',
    lineHeight: '22px',
    margin: '0 1px',
});
export var SearchTextWrapper = styled('div', {
    display: 'flex',
    alignItems: 'center',
});
export var DesktopLogo = styled(Logo, {
    display: 'flex',
    alignItems: 'center',
    color: '$primaryText',
    '@mobile': {
        display: 'none',
    },
});
export var MobileLogo = styled(ShortLogo, {
    display: 'none',
    '@mobile': {
        display: 'block',
    },
});
export var NavContainer = styled('div', {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    '@tablet': {
        display: 'none',
    },
    '@mobile': {
        display: 'none',
    },
});
export var ProfileContainer = styled('div', {
    display: 'flex',
    padding: '24px 0',
    '@mobile': {
        padding: '0',
    },
});
export var BurgerMenuOverlay = styled('div', {
    display: 'flex',
    justifyContent: 'center',
    width: '100vw',
    height: '104svh',
    transform: 'translateX(-120%)',
    position: 'fixed',
    top: 60,
    background: 'rgba(255, 255, 255, 0.7)',
    transition: 'opacity 0.3s ease, backdropFilter 0.3s ease',
    '@desktop': {
        display: 'none',
    },
    variants: {
        isOpen: {
            true: {
                opacity: 1,
                backdropFilter: 'Blur(15px)',
                zIndex: '98',
                pointerEvents: 'auto',
                transform: 'translateX(0)',
            },
            false: {
                backdropFilter: 'Blur(0px)',
                opacity: '0',
                zIndex: '-9999',
            },
        },
    },
});
export var BurgerMenuContent = styled('div', {
    marginBottom: '90px',
    width: '100%',
    padding: '0 16px',
});
export var BurgerContainer = styled('button', {
    all: 'unset',
    cursor: 'pointer',
    display: 'none',
    flexDirection: 'column',
    height: '12px',
    padding: '12px 12px',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@tablet': {
        display: 'flex',
    },
    '@mobile': {
        display: 'flex',
    },
});
var lineStyles = {
    width: '18px',
    height: '2px',
    borderRadius: 999,
    transition: 'transform 0.25s ease-in-out, opacity 0.1s ease-in-out',
};
export var Line1 = styled('div', __assign(__assign({}, lineStyles), { variants: {
        active: {
            true: {
                transform: 'translateY(5px) rotate(45deg)',
            },
        },
    } }));
export var Line2 = styled('div', __assign(__assign({}, lineStyles), { variants: {
        active: {
            true: {
                opacity: 0,
            },
        },
    } }));
export var Line3 = styled('div', __assign(__assign({}, lineStyles), { variants: {
        active: {
            true: {
                transform: 'translateY(-5px) rotate(-45deg)',
            },
        },
    } }));
export var ScrollLock = styled('div', {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 99,
    background: 'transparent',
    pointerEvents: 'none',
    variants: {
        isLock: {
            true: {
                display: 'block',
            },
            false: {
                display: 'none',
            },
        },
    },
});
