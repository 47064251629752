var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    isMenuOpen: false,
};
var widgetMenuSlice = createSlice({
    name: 'widgetMenuSlice',
    initialState: initialState,
    reducers: {
        toggleMenu: function (state) {
            state.isMenuOpen = !state.isMenuOpen;
        },
        setMenuState: function (state, action) {
            state.isMenuOpen = action.payload;
        },
    },
});
export var toggleMenu = (_a = widgetMenuSlice.actions, _a.toggleMenu), setMenuState = _a.setMenuState;
export default widgetMenuSlice.reducer;
