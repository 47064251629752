var _a;
import { styled } from '@/app/styles/mainStyles';
export var TooltipText = styled('p', {
    fontSize: '20px',
    color: '$black700',
    fontFamily: '$montserrat',
    lineHeight: '28px',
    fontWeight: '400',
    '&:hover': {
        cursor: 'pointer',
    },
});
export var NavigationIcon = styled('div', {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '$black700',
    width: '24px',
    height: '24px',
});
export var NavigationItem = styled('div', {
    display: 'flex',
    padding: '8px 16px',
    borderRadius: '8px',
    gap: '8px',
    alignItems: 'center',
    transition: 'background 0.15s',
    variants: {
        active: {
            true: (_a = {},
                _a["".concat(TooltipText)] = {
                    color: '$lightTextDefault',
                },
                _a["".concat(NavigationIcon)] = {
                    color: '$lightTextDefault',
                },
                _a),
        },
    },
    '&:hover': {
        cursor: 'pointer',
        background: '$controlsBgSecondaryDefault',
    },
});
