import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    isOpenSettings: false,
};
var selectWithSettingsSlice = createSlice({
    name: 'selectWithSettingsSlice',
    initialState: initialState,
    reducers: {
        setIsOpenSettings: function (state, action) {
            state.isOpenSettings = action.payload;
        },
    },
});
export default selectWithSettingsSlice.reducer;
export var setIsOpenSettings = selectWithSettingsSlice.actions.setIsOpenSettings;
// export { getFirstTicker };
