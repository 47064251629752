var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import toast from 'react-hot-toast';
import { ToastErrorContainerWrapper } from '@/widgets/ui/Toast/ToastSuccess/style';
import * as S from './style';
import { Text } from '@/shared/ui';
var ToastErrorsContent = function (props) {
    return (_jsxs(S.ToastErrorMessage, { children: [_jsx(S.ErrorIcon, {}), _jsx(Text, __assign({ css: { color: '$alwaysWhite !important' } }, { children: props.message }))] }));
};
var toastError = function (message, duration, position) {
    toast(function (t) { return (_jsx(ToastErrorsContent, { toast: t, message: message })); }, {
        style: ToastErrorContainerWrapper,
        duration: duration,
        position: position,
    });
};
export { toastError };
