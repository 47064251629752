import { styled } from '@/app/styles/mainStyles';
export var Label = styled('span', {
    position: 'absolute',
    pointerEvents: 'none',
    left: '18px',
    top: '16px',
    fontSize: '20px',
    lineHeight: '28px',
    color: '$textSecondary',
    transition: '0.2s ease all',
    '&[data-value-attribute="true"]': {
        top: '4px',
        left: '16px',
        fontSize: '12px',
    },
});
export var TextArea = styled('textarea', {
    background: '$bgInput',
    border: '1px solid transparent',
    borderRadius: '16px',
    fontWeight: 400,
    padding: '12px 16px',
    boxSizing: 'border-box',
    color: '$primaryText',
    fontSize: '20px',
    fontFamily: '$montserrat',
    lineHeight: 1.5715,
    resize: 'none',
    '&::-webkit-scrollbar': {
        display: 'none',
    },
    '&:disabled': {
        opacity: 0.3,
        cursor: 'not-allowed',
    },
    '@mobile': {
        fontSize: '16px !important',
        lineHeight: '24px',
    },
    variants: {
        typeInput: {
            default: {
                height: '30vh',
                width: '100%',
                '&::placeholder': {
                    color: '$textSecondary',
                },
                '&:hover': {
                    outline: 'none',
                    border: '1px solid $controlsStrokeHover',
                },
                '&:focus': {
                    outline: 'none',
                    border: '1px solid $controlsStrokeFocus',
                },
            },
        },
        withLabel: {
            true: {
                padding: '12px 16px 13px 16px',
                '&[data-value-attribute="true"]': {
                    padding: '24px 16px 13px 16px',
                },
            },
        },
        error: {
            true: {
                outline: 'none',
                border: '1px solid $error',
                '&:focus': {
                    outline: 'none',
                    border: '1px solid $error',
                },
                '&:hover': {
                    outline: 'none',
                    border: '1px solid $error',
                },
            },
        },
    },
});
export var ErrorContainer = styled('div', {
    padding: '0 16px',
    marginTop: '4px',
});
