var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BASE_FETCH_STATUS } from '@/shared/api/constants/baseFetch.status';
import { START_FETCH_STATUS } from '@/shared/api/constants/startFetch.status';
var initialState = {
    isOpenBacktest: false,
    backtestData: null,
    backtestStrategyStatus: BASE_FETCH_STATUS,
    isShowData: false,
    tickersInfoResult: null,
    typeBlocksStatus: BASE_FETCH_STATUS,
    history: null,
    historyStatus: BASE_FETCH_STATUS,
    deleteStatus: BASE_FETCH_STATUS,
    isBacktestSuccess: false,
};
var getTickersInfo = createAsyncThunk('backtest/getTickersInfo', function (_a, thunkAPI) {
    var ticker_ids = _a.ticker_ids;
    return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, thunkAPI.extra.api.post('/tickers/tickers_details/', { ticker_ids: ticker_ids })];
                case 1:
                    response = _b.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    error_1 = _b.sent();
                    return [2 /*return*/, thunkAPI.rejectWithValue(error_1)];
                case 3: return [2 /*return*/];
            }
        });
    });
});
var getTaskId = createAsyncThunk('backtestSlice/getTaskId', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.post("/strategy-workspace/".concat(payload.id, "/run/backtest/"), payload.data)];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_1 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_1)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var pollTaskStatus = createAsyncThunk('backtestSlice/pollTaskStatus', function (_a, thunkAPI) {
    var task_id = _a.task_id;
    return __awaiter(void 0, void 0, void 0, function () {
        var status, isShowData, response, data;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    status = 'RUNNING';
                    _b.label = 1;
                case 1:
                    if (!(status !== 'SUCCESS')) return [3 /*break*/, 4];
                    isShowData = thunkAPI.getState().backtestReducer.isShowData;
                    if (!isShowData) {
                        return [2 /*return*/, thunkAPI.rejectWithValue('Показ данных остановлен')];
                    }
                    return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(resolve, 2000); })];
                case 2:
                    _b.sent();
                    return [4 /*yield*/, thunkAPI.extra.api.post('/strategy-workspace/run/get_result/', { task_id: task_id })];
                case 3:
                    response = _b.sent();
                    data = response.data;
                    status = data.task_status;
                    if (status === 'SUCCESS') {
                        return [2 /*return*/, data];
                    }
                    if (status === 'FAILURE') {
                        return [2 /*return*/, thunkAPI.rejectWithValue('Ошибка подсчета данных')];
                    }
                    if (status === 'The strategy has already been run') {
                        return [2 /*return*/, thunkAPI.rejectWithValue('The strategy has already been run')];
                    }
                    return [3 /*break*/, 1];
                case 4: return [2 /*return*/];
            }
        });
    });
});
var backtestStrategy = createAsyncThunk('backtestSlice/backtestStrategy', function (_a, thunkAPI) {
    var blockId = _a.blockId, strategyId = _a.strategyId, periodStart = _a.periodStart, periodFinish = _a.periodFinish;
    return __awaiter(void 0, void 0, void 0, function () {
        var taskId, backtestData, data, state_data, branch_blocks, tickersInfo, error_2;
        var _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    _d.trys.push([0, 6, , 7]);
                    return [4 /*yield*/, thunkAPI
                            .dispatch(getTaskId({ id: strategyId, data: { block_pk: blockId, period_start: periodStart, period_finish: periodFinish } }))
                            .unwrap()];
                case 1:
                    taskId = _d.sent();
                    return [4 /*yield*/, thunkAPI
                            .dispatch(pollTaskStatus({ task_id: taskId.task_id }))
                            .unwrap()];
                case 2:
                    backtestData = _d.sent();
                    data = backtestData.data, state_data = backtestData.state_data, branch_blocks = backtestData.branch_blocks;
                    if (!(((_c = (_b = branch_blocks === null || branch_blocks === void 0 ? void 0 : branch_blocks[0]) === null || _b === void 0 ? void 0 : _b.in) === null || _c === void 0 ? void 0 : _c.length) > 0)) return [3 /*break*/, 4];
                    return [4 /*yield*/, thunkAPI
                            .dispatch(getTickersInfo({ ticker_ids: branch_blocks[0].in }))
                            .unwrap()];
                case 3:
                    tickersInfo = _d.sent();
                    return [2 /*return*/, { data: data, state_data: state_data, branch_blocks: branch_blocks, tickersData: tickersInfo.tickers_data }];
                case 4: return [2 /*return*/, thunkAPI.rejectWithValue('Tickers not found')];
                case 5: return [3 /*break*/, 7];
                case 6:
                    error_2 = _d.sent();
                    return [2 /*return*/, thunkAPI.rejectWithValue(error_2)];
                case 7: return [2 /*return*/];
            }
        });
    });
});
var getBacktestHistory = createAsyncThunk('backtestSlice/getBacktestHistory', function (_a, thunkAPI) {
    var id = _a.id, signal = _a.signal;
    return __awaiter(void 0, void 0, void 0, function () {
        var response, e_2;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, thunkAPI.extra.api.get("/strategy-workspace/".concat(id, "/backtest_history/"), { signal: signal })];
                case 1:
                    response = _b.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    e_2 = _b.sent();
                    console.error(e_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
});
var deleteBacktestHistoryDetail = createAsyncThunk('backtestSlice/deleteBacktestHistoryDetail', function (_a, thunkAPI) {
    var id = _a.id, task_id = _a.task_id;
    return __awaiter(void 0, void 0, void 0, function () {
        var error_3;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, thunkAPI.extra.api.delete("/strategy-workspace/".concat(id, "/backtest_history/detail/"), { data: { task_id: task_id } })];
                case 1:
                    _b.sent();
                    return [3 /*break*/, 3];
                case 2:
                    error_3 = _b.sent();
                    return [2 /*return*/, thunkAPI.rejectWithValue(error_3)];
                case 3: return [2 /*return*/];
            }
        });
    });
});
var getBacktestHistoryDetail = createAsyncThunk('backtestSlice/getBacktestHistoryDetail', function (_a, thunkAPI) {
    var id = _a.id, task_id = _a.task_id;
    return __awaiter(void 0, void 0, void 0, function () {
        var response, _b, data, state_data, branch_blocks, tickersInfo, error_4;
        var _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    _e.trys.push([0, 5, , 6]);
                    return [4 /*yield*/, thunkAPI.extra.api.post("/strategy-workspace/".concat(id, "/backtest_history/detail/"), { task_id: task_id })];
                case 1:
                    response = _e.sent();
                    _b = response.data, data = _b.data, state_data = _b.state_data, branch_blocks = _b.branch_blocks;
                    if (!(((_d = (_c = branch_blocks === null || branch_blocks === void 0 ? void 0 : branch_blocks[0]) === null || _c === void 0 ? void 0 : _c.in) === null || _d === void 0 ? void 0 : _d.length) > 0)) return [3 /*break*/, 3];
                    return [4 /*yield*/, thunkAPI
                            .dispatch(getTickersInfo({ ticker_ids: branch_blocks[0].in }))
                            .unwrap()];
                case 2:
                    tickersInfo = _e.sent();
                    return [2 /*return*/, { data: data, state_data: state_data, branch_blocks: branch_blocks, tickersData: tickersInfo.tickers_data }];
                case 3: return [2 /*return*/, thunkAPI.rejectWithValue('Tickers not found')];
                case 4: return [3 /*break*/, 6];
                case 5:
                    error_4 = _e.sent();
                    return [2 /*return*/, thunkAPI.rejectWithValue(error_4)];
                case 6: return [2 /*return*/];
            }
        });
    });
});
var backtestSlice = createSlice({
    name: 'backtestSlice',
    initialState: initialState,
    reducers: {
        resetAll: function () { return initialState; },
        setIsOpenBacktest: function (state, _a) {
            var payload = _a.payload;
            state.isOpenBacktest = payload;
        },
        toggleShowData: function (state, _a) {
            var payload = _a.payload;
            state.isShowData = payload;
        },
        clearBacktestStrategyData: function (state) {
            state.backtestData = null;
            state.backtestStrategyStatus = BASE_FETCH_STATUS;
            state.isShowData = false;
            state.tickersInfoResult = null;
            state.typeBlocksStatus = BASE_FETCH_STATUS;
        },
        resetLoadingStatus: function (state) {
            state.backtestStrategyStatus = BASE_FETCH_STATUS;
        },
    },
    extraReducers: function (builder) {
        builder.addCase(backtestStrategy.pending, function (state) {
            state.backtestStrategyStatus = START_FETCH_STATUS;
            state.backtestData = null;
            state.isBacktestSuccess = false;
        });
        builder.addCase(backtestStrategy.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.backtestData = payload;
            state.isBacktestSuccess = true;
            state.backtestStrategyStatus.isLoading = false;
        });
        builder.addCase(backtestStrategy.rejected, function (state, _a) {
            var payload = _a.payload;
            state.backtestStrategyStatus.error = payload;
            state.backtestStrategyStatus.isLoading = false;
            state.backtestData = null;
        });
        builder.addCase(getBacktestHistory.pending, function (state) {
            state.historyStatus = START_FETCH_STATUS;
            state.history = null;
        });
        builder.addCase(getBacktestHistory.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.history = payload;
            state.historyStatus.isLoading = false;
        });
        builder.addCase(getBacktestHistory.rejected, function (state, _a) {
            var payload = _a.payload;
            state.historyStatus.error = payload;
            state.historyStatus.isLoading = false;
        });
        builder.addCase(deleteBacktestHistoryDetail.pending, function (state) {
            state.deleteStatus = START_FETCH_STATUS;
        });
        builder.addCase(deleteBacktestHistoryDetail.fulfilled, function (state, _a) {
            var meta = _a.meta;
            state.deleteStatus.isLoading = false;
            var taskId = meta.arg.task_id;
            if (state.history) {
                state.history = Object.fromEntries(Object.entries(state.history).filter(function (_a) {
                    var key = _a[0];
                    return key !== taskId;
                }));
            }
        });
        builder.addCase(deleteBacktestHistoryDetail.rejected, function (state, _a) {
            var payload = _a.payload;
            state.deleteStatus.error = payload;
            state.deleteStatus.isLoading = false;
        });
        builder.addCase(getBacktestHistoryDetail.pending, function (state) {
            state.backtestStrategyStatus = START_FETCH_STATUS;
            state.backtestData = null;
            state.isBacktestSuccess = false;
        });
        builder.addCase(getBacktestHistoryDetail.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.backtestData = payload;
            state.isBacktestSuccess = true;
            state.backtestStrategyStatus.isLoading = false;
        });
        builder.addCase(getBacktestHistoryDetail.rejected, function (state, _a) {
            var payload = _a.payload;
            state.backtestStrategyStatus.error = payload;
            state.backtestStrategyStatus.isLoading = false;
            state.backtestData = null;
        });
    },
});
export var clearBacktestStrategyData = (_a = backtestSlice.actions, _a.clearBacktestStrategyData), toggleShowData = _a.toggleShowData, setIsOpenBacktest = _a.setIsOpenBacktest, resetAll = _a.resetAll, resetLoadingStatus = _a.resetLoadingStatus;
export default backtestSlice.reducer;
export { backtestStrategy, getBacktestHistory, deleteBacktestHistoryDetail, getBacktestHistoryDetail, };
