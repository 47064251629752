import { jsx as _jsx } from "react/jsx-runtime";
import * as S from './style';
import { BASE_URL } from '@/shared/api/api';
import { EmptyAvatar } from '@/shared/ui/EmptyAvatar/EmptyAvatar';
var AvatarWithName = function (_a) {
    var userData = _a.userData;
    var avatarPath = (userData === null || userData === void 0 ? void 0 : userData.profile_image) ? new URL(userData.profile_image, BASE_URL).href + "?t=".concat(new Date().getTime()) : '';
    return (_jsx(S.AvatarWithNameContainer, { children: (userData === null || userData === void 0 ? void 0 : userData.profile_image) ? (_jsx(S.ImgContainer, { src: avatarPath })) : (_jsx(EmptyAvatar, { firstName: userData === null || userData === void 0 ? void 0 : userData.username, size: 44 })) }));
};
export { AvatarWithName };
