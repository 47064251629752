var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { createAsyncThunk, createSlice, current, createAction } from '@reduxjs/toolkit';
import { BASE_FETCH_STATUS } from '@/shared/api/constants/baseFetch.status';
import { START_FETCH_STATUS } from '@/shared/api/constants/startFetch.status';
import { checkEdges, getCurrentEdges, handlerErrorEdge, prepareEdge, transformNode, updateFunctionNode, updateSchemeNodes, updateSchemeStickers, } from '@/entities/createWorkspace/model/utils';
var initialState = {
    workspace: null,
    workspaceStatus: BASE_FETCH_STATUS,
    isLoadingStrategy: BASE_FETCH_STATUS,
    updateWorkspaceStatus: BASE_FETCH_STATUS,
    functionList: [],
    weigherFunctionList: [],
    baseFunctionList: [],
    limitPriceFunctions: [],
    edges: [],
    nodes: [],
    notifications: {},
    tickers: {
        results: [],
        next: '',
        prev: '',
    },
    localTickerBox: {
        results: [],
        next: '',
        prev: '',
    },
    globalTickerBox: {
        results: [],
        next: '',
        prev: '',
    },
    attributeTickers: [],
    deposit: null,
    depositStatus: BASE_FETCH_STATUS,
    isHasTickers: {},
    isLongShort: {},
    branch: null,
    isClickedOutsideNodes: true,
    isHoveredNode: false,
    creatingStickyNote: null,
    stickers: null,
    isRunOrBacktestModal: null,
    oracles: null,
    settings: null,
    functionsCategories: null,
    isDragging: false,
    isConnection: false,
    isTextareaFocused: false,
    isActive: null,
    isOpenMatrixPanel: false,
};
var getCurrentWorkspace = createAsyncThunk('workspaceStrategySlice/getCurrentWorkspace', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.get("/strategy-workspace/".concat(payload.id, "/"))];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_1 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_1)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var getActiveStatusStrategy = createAsyncThunk('workspaceStrategySlice/getActiveStatusStrategy', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.get("/strategy-workspace/".concat(payload.id, "/"))];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_2 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_2)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var updateWorkspace = createAsyncThunk('workspaceStrategySlice/updateWorkspace', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.patch("/strategy-workspace/".concat(payload.id, "/"), payload.data)];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_3 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_3)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var getTickers = createAsyncThunk('workspaceStrategySlice/getTickers', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.get("/strategy-block/".concat(payload.id, "/tickers/?limit=").concat(payload.limit, "&offset=").concat(payload.offset))];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_4 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_4)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var updateTickers = createAsyncThunk('workspaceStrategySlice/updateTickers', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.post("/strategy-block/".concat(payload.id, "/tickers/"), payload.data)];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_5 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_5)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var getAttributeTickers = createAsyncThunk('workspaceStrategySlice/getAttributeTickers', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.get("/tickers/?limit=".concat(payload.limit, "&offset=").concat(payload.offset))];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_6 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_6)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var addNewNodeForWorksapce = createAsyncThunk('workspaceStrategySlice/addNewNodeForWorksapce', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_7;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.post('/strategy-block/', payload)];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_7 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_7)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var deleteStrategyBlockEdge = createAsyncThunk('workspaceStrategySlice/deleteStrategyBlockEdge', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_8;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.delete('/strategy-block/edge/', { data: payload })];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_8 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_8)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var deleteNodeFromWorkspace = createAsyncThunk('workspaceStrategySlice/deleteNodeFromWorkspace', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var e_9;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.delete("/strategy-block/".concat(payload.id, "/"))];
            case 1:
                _a.sent();
                return [2 /*return*/, payload];
            case 2:
                e_9 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_9)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var getNodeFromWorkspace = createAsyncThunk('workspaceStrategySlice/getNodeFromWorkspace', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var e_10;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.get("/strategy-block/".concat(payload.id, "/"))];
            case 1: return [2 /*return*/, _a.sent()];
            case 2:
                e_10 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_10)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var createWorkspaceEdge = createAsyncThunk('workspaceStrategySlice/createWorkspaceChain', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_11;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.post('/strategy-block/edge/', payload)];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_11 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_11)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var updateWorkspaceEdge = createAsyncThunk('workspaceStrategySlice/updateWorkspaceEdge', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_12;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.patch('/strategy-block/edge/', payload)];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_12 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_12)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var updateNodeForWorkspace = createAsyncThunk('workspaceStrategySlice/updateNodeForWorkspace', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_13;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.patch("/strategy-block/".concat(payload.id, "/"), payload.node)];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_13 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_13)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var getFunctionList = createAsyncThunk('workspaceStrategySlice/getFunctionList', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_14;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.get('/functions/base_functions/?limit=160&offset=0/')];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data.results];
            case 2:
                e_14 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_14)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var getLimitPriceFunction = createAsyncThunk('workspaceStrategySlice/getLimitPriceFunction', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_15;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.get('/functions/limit_price_functions/?limit=160&offset=0/')];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data.results];
            case 2:
                e_15 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_15)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var getWeighterFunctionList = createAsyncThunk('workspaceStrategySlice/getWeighterFunctionList', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_16;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.get('/functions/weighter_functions/?limit=160&offset=0/')];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data.results];
            case 2:
                e_16 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_16)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var getCompareExpression = createAsyncThunk('workspaceStrategySlice/getCompareExpression', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_17;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.get("/strategy-block/".concat(payload.id, "/compare_expression/"))];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_17 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_17)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var updateCompareExpression = createAsyncThunk('workspaceStrategySlice/updateCompareExpression', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_18;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.put("/strategy-block/".concat(payload.id, "/compare_expression/"), payload.node)];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_18 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_18)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var getLocalTickerbox = createAsyncThunk('workspaceStrategySlice/getLocalTickerbox', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_19;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.get("ticker-box/local/?limit=".concat(payload.limit, "&offset=").concat(payload.offset))];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_19 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_19)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var getGlobalTickerbox = createAsyncThunk('workspaceStrategySlice/getGlobalTickerbox', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_20;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.get("ticker-box/global/?limit=".concat(payload.limit, "&offset=").concat(payload.offset))];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_20 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_20)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var addCustomTickerbox = createAsyncThunk('workspaceStrategySlice/addCustomTickerbox', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_21;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.post('/ticker-box/', payload)];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_21 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_21)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var updateTickerbox = createAsyncThunk('workspaceStrategySlice/updateTickerbox', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_22;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.put("/ticker-box/".concat(payload.id, "/"), { name: payload.name })];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_22 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_22)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var removeTickerbox = createAsyncThunk('workspaceStrategySlice/removeTickerbox', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var e_23;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.delete("/ticker-box/".concat(payload, "/"))];
            case 1:
                _a.sent();
                return [2 /*return*/, payload];
            case 2:
                e_23 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_23)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var getTickerAttributes = createAsyncThunk('workspaceStrategySlice/getTickerAttributes', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_24;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.get("/attribute/?type=".concat(payload.type))];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_24 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_24)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var getAttributeFields = createAsyncThunk('workspaceStrategySlice/getAttributeFields', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_25;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.get("/attribute/".concat(payload.name, "/"))];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_25 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_25)];
            case 3: return [2 /*return*/];
        }
    });
}); });
// const updateFunctionExpression = createAsyncThunk<any, any, { state: AppState, extra: ThunkExtraArgs }>(
//   'workspaceStrategySlice/updateFunctionExpression',
//   async (payload, thunkAPI) => {
//     try {
//       const resp = await thunkAPI.extra.api.patch(
//         `/function-expression/${payload.compare_expression}/`,
//         { function_name: payload.function_name, compare_expression: payload.compare_expression },
//       );
//       return { data: resp.data, isLeft: payload.is_left };
//     } catch (e) {
//       return thunkAPI.rejectWithValue(e);
//     }
//   },
// );
var createFunctionExpression = createAsyncThunk('workspaceStrategySlice/createFunctionExpression', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_26;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.post('/function-expression/', payload)];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, { data: resp.data, isLeft: payload.is_left }];
            case 2:
                e_26 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_26)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var updateOracles = createAsyncThunk('workspaceStrategySlice/updateOracles', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_27;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.post("/strategy-block/".concat(payload.id, "/oracles/"), payload.data)];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_27 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_27)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var getOracleData = createAsyncThunk('workspaceStrategySlice/getOracleData', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_28;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.get("/strategy-block/".concat(payload.id, "/oracles/"))];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_28 = _a.sent();
                return [2 /*return*/, null];
            case 3: return [2 /*return*/];
        }
    });
}); });
var createValueExpression = createAsyncThunk('workspaceStrategySlice/createValueExpression', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_29;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.post('/value-expression/', payload)];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_29 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_29)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var getCurrentDeposit = createAsyncThunk('workspaceStrategySlice/getCurrentDeposit', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_30;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.get("/strategy-workspace/".concat(payload.id, "/deposit"))];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_30 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_30)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var updateCurrentDeposit = createAsyncThunk('workspaceStrategySlice/updateCurrentDeposit', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_31;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.put("/strategy-workspace/".concat(payload.id, "/deposit/"), payload.data)];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_31 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_31)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var getBranch = createAsyncThunk('workspaceStrategySlice/getBranch', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_32;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.get("/strategy-block/".concat(payload.id, "/get_branch"))];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_32 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_32)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var activateStrategy = createAsyncThunk('workspaceStrategySlice/activateStrategy', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_33;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.post("/strategy-workspace/".concat(payload.id, "/activate/"))];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_33 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_33)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var deactivateStrategy = createAsyncThunk('workspaceStrategySlice/deactivateStrategy', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_34;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.post("/strategy-workspace/".concat(payload.id, "/deactivate/"))];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_34 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_34)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var getStickers = createAsyncThunk('workspaceStrategySlice/getStickers', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var limit, offset, strategy_id, response, e_35;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                limit = payload.limit, offset = payload.offset, strategy_id = payload.strategy_id;
                return [4 /*yield*/, thunkAPI.extra.api.get('/sticker/', {
                        params: {
                            limit: limit,
                            offset: offset,
                            strategy_id: strategy_id,
                        },
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.data];
            case 2:
                e_35 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_35)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var createStickerNode = createAsyncThunk('workspaceStrategySlice/createStickerNode', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var response, e_36;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.post('/sticker/', payload.data)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.data];
            case 2:
                e_36 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_36)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var updateStickerNode = createAsyncThunk('workspaceStrategySlice/updateStickerNode', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_37;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.patch("/sticker/".concat(payload.id, "/"), payload.data)];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_37 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_37)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var deleteStickerNode = createAsyncThunk('workspaceStrategySlice/deleteStickerNode', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var e_38;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.delete("/sticker/".concat(payload.id, "/"))];
            case 1:
                _a.sent();
                return [2 /*return*/, payload];
            case 2:
                e_38 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_38)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var getFunctionsCategories = createAsyncThunk('newsSlice/getFunctionsCategories', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var queryParams, resp, formatObject_1, formattedData, e_39;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                queryParams = [
                    payload.category ? "category=".concat(encodeURIComponent(payload.category)) : '',
                    payload.subcategory ? "subcategory=".concat(encodeURIComponent(payload.subcategory)) : '',
                ].filter(Boolean).join('&');
                return [4 /*yield*/, thunkAPI.extra.api.get("/functions/categories?".concat(queryParams))];
            case 1:
                resp = _a.sent();
                formatObject_1 = function (obj) {
                    return Array.isArray(obj)
                        ? obj.map(function (item) {
                            return typeof item === 'string'
                                ? item
                                    .replace(/([a-z0-9])([A-Z])/g, '$1 $2')
                                    .replace(/([A-Z]+)([A-Z][a-z])/g, '$1 $2')
                                    .toLowerCase()
                                : formatObject_1(item);
                        })
                        : typeof obj === 'object' && obj !== null
                            ? Object.fromEntries(Object.entries(obj).map(function (_a) {
                                var key = _a[0], value = _a[1];
                                return [key, formatObject_1(value)];
                            }))
                            : obj;
                };
                formattedData = formatObject_1(resp.data);
                return [2 /*return*/, formattedData];
            case 2:
                e_39 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_39)];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var resetWorkspaceState = createAction('workspaceStrategySlice/resetWorkspaceState');
var createWorkspaceSlice = createSlice({
    name: 'createWorkspaceSlice',
    initialState: initialState,
    reducers: {
        resetCurrentWorkspace: function () { return initialState; },
        setEdgesState: function (state, _a) {
            var payload = _a.payload;
            state.edges = payload;
        },
        setNodesState: function (state, _a) {
            var payload = _a.payload;
            state.nodes = payload;
        },
        setErrorEdge: function (state, _a) {
            var payload = _a.payload;
            state.edges = handlerErrorEdge(current(state).edges, payload.id);
        },
        addIsHasTickers: function (state, _a) {
            var payload = _a.payload;
            state.isHasTickers = __assign(__assign({}, state.isHasTickers), payload);
        },
        addIsLongShort: function (state, _a) {
            var payload = _a.payload;
            state.isLongShort = __assign(__assign({}, state.isLongShort), payload);
        },
        addNotifications: function (state, _a) {
            var payload = _a.payload;
            state.notifications = __assign(__assign({}, state.notifications), payload);
        },
        setIsClickedOutsideNodes: function (state) {
            state.isClickedOutsideNodes = !state.isClickedOutsideNodes;
        },
        setIsHoveredNode: function (state, _a) {
            var payload = _a.payload;
            state.isHoveredNode = payload;
        },
        setIsOpenMatrixPanel: function (state, _a) {
            var payload = _a.payload;
            state.isOpenMatrixPanel = payload;
        },
        setStickyNoteColor: function (state, _a) {
            var payload = _a.payload;
            state.creatingStickyNote = payload;
        },
        setIsRunOrBacktestModal: function (state, _a) {
            var payload = _a.payload;
            state.isRunOrBacktestModal = payload;
        },
        setOraclesOnStrategyOpen: function (state, _a) {
            var payload = _a.payload;
            state.oracles = payload;
        },
        removeOracle: function (state, _a) {
            var nodeId = _a.payload;
            if (state.oracles) {
                delete state.oracles[nodeId];
            }
        },
        addOrUpdateOracle: function (state, _a) {
            var _b;
            var payload = _a.payload;
            var nodeId = payload.nodeId, value = payload.value;
            if (state.oracles) {
                state.oracles[nodeId] = value;
            }
            else {
                state.oracles = (_b = {}, _b[nodeId] = value, _b);
            }
        },
        setSettingsOnStrategyOpen: function (state, _a) {
            var payload = _a.payload;
            state.settings = payload;
        },
        setDeposit: function (state, _a) {
            var payload = _a.payload;
            state.deposit = payload;
        },
        addOrUpdateSettings: function (state, _a) {
            var _b;
            var payload = _a.payload;
            var nodeId = payload.nodeId, value = payload.value;
            if (state.settings) {
                state.settings[nodeId] = value;
            }
            else {
                state.settings = (_b = {}, _b[nodeId] = value, _b);
            }
        },
        updateNodeRootState: function (state, _a) {
            var payload = _a.payload;
            state.nodes = state.nodes.map(function (node) {
                return node.id == payload.data.target ? __assign(__assign({}, node), { isRoot: true }) : node;
            });
        },
        setDraggingState: function (state, action) {
            state.isDragging = action.payload;
        },
        setConnectionState: function (state, action) {
            state.isConnection = action.payload;
        },
        setTextareaFocused: function (state, action) {
            state.isTextareaFocused = action.payload;
        },
        setInitialStickers: function (state, _a) {
            var payload = _a.payload;
            state.nodes = __spreadArray(__spreadArray([], state.nodes, true), updateSchemeStickers(payload), true);
        },
    },
    extraReducers: function (builder) {
        builder.addCase(getCurrentWorkspace.pending, function (state) {
            state.workspaceStatus = START_FETCH_STATUS;
            state.isLoadingStrategy = START_FETCH_STATUS;
        });
        builder.addCase(getCurrentWorkspace.rejected, function (state, _a) {
            var payload = _a.payload;
            state.workspaceStatus.isLoading = false;
            state.isLoadingStrategy.isLoading = false;
            state.workspaceStatus.error = payload;
        });
        builder.addCase(getCurrentWorkspace.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.workspaceStatus.isLoading = false;
            state.isLoadingStrategy.isLoading = false;
            state.edges = getCurrentEdges(payload.edges);
            state.nodes = updateSchemeNodes(payload.blocks);
            state.nodes = __spreadArray(__spreadArray([], state.nodes, true), updateSchemeStickers(payload.stickers), true);
            state.workspace = payload;
        });
        builder.addCase(getActiveStatusStrategy.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.isActive = payload.is_active;
        });
        builder.addCase(updateNodeForWorkspace.pending, function (state) {
            state.workspaceStatus = START_FETCH_STATUS;
        });
        builder.addCase(updateNodeForWorkspace.rejected, function (state, _a) {
            var payload = _a.payload;
            state.workspaceStatus.isLoading = false;
            state.workspaceStatus.error = payload;
        });
        builder.addCase(updateNodeForWorkspace.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.workspaceStatus.isLoading = false;
            var id = payload.id.toString();
            state.nodes = state.nodes.map(function (item) { return item.id === id ? transformNode(payload) : item; });
        });
        builder.addCase(deleteNodeFromWorkspace.pending, function (state) {
            state.workspaceStatus = START_FETCH_STATUS;
        });
        builder.addCase(deleteNodeFromWorkspace.rejected, function (state, _a) {
            var payload = _a.payload;
            state.workspaceStatus.isLoading = false;
            state.workspaceStatus.error = payload;
        });
        builder.addCase(deleteNodeFromWorkspace.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.workspaceStatus.isLoading = false;
            state.nodes = state.nodes.filter(function (node) { return node.id !== payload.id.toString(); });
        });
        builder.addCase(createWorkspaceEdge.pending, function (state) {
            state.workspaceStatus = START_FETCH_STATUS;
        });
        builder.addCase(createWorkspaceEdge.rejected, function (state, _a) {
            var payload = _a.payload;
            state.workspaceStatus.isLoading = false;
            state.workspaceStatus.error = payload;
        });
        builder.addCase(createWorkspaceEdge.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.edges = __spreadArray(__spreadArray([], state.edges, true), [prepareEdge(payload)], false);
            state.workspaceStatus.isLoading = false;
            state.nodes = state.nodes.map(function (node) {
                return node.id == payload.target ? __assign(__assign({}, node), { isRoot: false }) : node;
            });
        });
        builder.addCase(getTickers.pending, function (state) {
            state.workspaceStatus = START_FETCH_STATUS;
        });
        builder.addCase(getTickers.rejected, function (state) {
            state.workspaceStatus.isLoading = false;
        });
        builder.addCase(getTickers.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.workspaceStatus.isLoading = false;
            state.tickers = payload;
        });
        builder.addCase(getFunctionList.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.functionList = payload;
            state.baseFunctionList = payload;
        });
        builder.addCase(getLimitPriceFunction.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.limitPriceFunctions = payload;
        });
        builder.addCase(getWeighterFunctionList.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.functionList = payload;
            state.weigherFunctionList = payload;
        });
        builder.addCase(getLocalTickerbox.rejected, function (state) {
            state.workspaceStatus.isLoading = false;
        });
        builder.addCase(getLocalTickerbox.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.workspaceStatus.isLoading = false;
            state.localTickerBox = payload;
        });
        builder.addCase(getLocalTickerbox.pending, function () {
            // state.workspaceStatus = START_FETCH_STATUS;
        });
        builder.addCase(getGlobalTickerbox.rejected, function (state) {
            state.workspaceStatus.isLoading = false;
        });
        builder.addCase(getGlobalTickerbox.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.workspaceStatus.isLoading = false;
            state.globalTickerBox = payload;
        });
        builder.addCase(getGlobalTickerbox.pending, function () {
            // state.workspaceStatus = START_FETCH_STATUS;
        });
        builder.addCase(addCustomTickerbox.rejected, function (state) {
            state.workspaceStatus.isLoading = false;
        });
        builder.addCase(addCustomTickerbox.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.workspaceStatus.isLoading = false;
            state.localTickerBox.results.push(__assign(__assign({}, payload), { nowAdd: true, edit: true }));
        });
        builder.addCase(addCustomTickerbox.pending, function (state) {
            state.workspaceStatus = START_FETCH_STATUS;
        });
        builder.addCase(updateTickerbox.rejected, function (state) {
            state.workspaceStatus.isLoading = false;
        });
        builder.addCase(updateTickerbox.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.workspaceStatus.isLoading = false;
            var currentState = current(state.localTickerBox.results);
            state.localTickerBox.results = currentState.map(function (el) {
                if (el.id === payload.id) {
                    return payload;
                }
                return el;
            });
        });
        builder.addCase(updateTickerbox.pending, function (state) {
            state.workspaceStatus = START_FETCH_STATUS;
        });
        builder.addCase(removeTickerbox.rejected, function (state) {
            state.workspaceStatus.isLoading = false;
        });
        builder.addCase(removeTickerbox.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.workspaceStatus.isLoading = false;
            state.localTickerBox.results = state.localTickerBox.results.filter(function (el) { return el.id !== payload; });
        });
        builder.addCase(removeTickerbox.pending, function (state) {
            state.workspaceStatus = START_FETCH_STATUS;
        });
        builder.addCase(createFunctionExpression.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.nodes = updateFunctionNode(state.nodes, payload);
        });
        builder.addCase(getAttributeTickers.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.attributeTickers = payload.results.map(function (item) { return ({ id: item.id, value: item.id, text: item.name }); });
        });
        builder.addCase(updateWorkspaceEdge.fulfilled, function (state, _a) {
            var payload = _a.payload;
            var updateArray = current(state).edges.map(function (item) {
                return item.id === payload.id ? __assign(__assign({}, item), { label: payload.priority }) : item;
            });
            state.edges = checkEdges(updateArray, payload);
        });
        // builder.addCase(updateFunctionExpression.fulfilled, (state, { payload }) => {
        //   state.nodes = updateFunctionNode(state.nodes, payload);
        // });
        builder.addCase(updateWorkspace.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.workspace = payload;
            state.updateWorkspaceStatus.isLoading = false;
        });
        builder.addCase(updateWorkspace.pending, function (state) {
            state.updateWorkspaceStatus.isLoading = true;
        });
        builder.addCase(updateWorkspace.rejected, function (state, _a) {
            var payload = _a.payload;
            state.updateWorkspaceStatus.isLoading = false;
            state.updateWorkspaceStatus.error = payload;
        });
        builder.addCase(getCurrentDeposit.pending, function (state) {
            state.depositStatus.isLoading = true;
        });
        builder.addCase(getCurrentDeposit.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.deposit = payload.deposit;
        });
        builder.addCase(getCurrentDeposit.rejected, function (state, _a) {
            var payload = _a.payload;
            state.depositStatus.isLoading = false;
            state.depositStatus.error = payload;
        });
        builder.addCase(updateCurrentDeposit.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.deposit = payload.deposit;
        });
        builder.addCase(updateCurrentDeposit.pending, function (state) {
            state.depositStatus.isLoading = true;
        });
        builder.addCase(updateCurrentDeposit.rejected, function (state, _a) {
            var payload = _a.payload;
            state.depositStatus.isLoading = false;
            state.depositStatus.error = payload;
        });
        builder.addCase(getBranch.pending, function (state) {
            // state.depositStatus.isLoading = true;
        });
        builder.addCase(getBranch.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.branch = payload;
        });
        builder.addCase(getBranch.rejected, function (state, _a) {
            var payload = _a.payload;
            // state.depositStatus.isLoading = false;
            // state.depositStatus.error = payload as any;
        });
        builder.addCase(deactivateStrategy.fulfilled, function (state) {
            if (state.workspace) {
                state.workspace.is_active = false;
            }
            state.workspaceStatus.isLoading = false;
        });
        builder.addCase(activateStrategy.fulfilled, function (state) {
            if (state.workspace) {
                state.workspace.is_active = true;
            }
            state.workspaceStatus.isLoading = false;
        });
        builder.addCase(getStickers.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.nodes = __spreadArray(__spreadArray([], state.nodes, true), updateSchemeStickers(payload), true);
        });
        builder.addCase(getFunctionsCategories.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.functionsCategories = payload;
        });
    },
});
export default createWorkspaceSlice.reducer;
export var setEdgesState = (_a = createWorkspaceSlice.actions, _a.setEdgesState), setNodesState = _a.setNodesState, setErrorEdge = _a.setErrorEdge, addIsHasTickers = _a.addIsHasTickers, addIsLongShort = _a.addIsLongShort, setIsClickedOutsideNodes = _a.setIsClickedOutsideNodes, setIsHoveredNode = _a.setIsHoveredNode, setIsOpenMatrixPanel = _a.setIsOpenMatrixPanel, setStickyNoteColor = _a.setStickyNoteColor, setIsRunOrBacktestModal = _a.setIsRunOrBacktestModal, setOraclesOnStrategyOpen = _a.setOraclesOnStrategyOpen, removeOracle = _a.removeOracle, addOrUpdateOracle = _a.addOrUpdateOracle, setSettingsOnStrategyOpen = _a.setSettingsOnStrategyOpen, addOrUpdateSettings = _a.addOrUpdateSettings, updateNodeRootState = _a.updateNodeRootState, setDraggingState = _a.setDraggingState, setConnectionState = _a.setConnectionState, setTextareaFocused = _a.setTextareaFocused, setDeposit = _a.setDeposit, setInitialStickers = _a.setInitialStickers, resetCurrentWorkspace = _a.resetCurrentWorkspace, addNotifications = _a.addNotifications;
export { getCurrentWorkspace, addNewNodeForWorksapce, updateNodeForWorkspace, deleteNodeFromWorkspace, createWorkspaceEdge, getFunctionList, getWeighterFunctionList, getCompareExpression, createFunctionExpression, getTickers, createValueExpression, getLocalTickerbox, getGlobalTickerbox, updateTickerbox, removeTickerbox, addCustomTickerbox, updateOracles, getOracleData, updateCompareExpression, getTickerAttributes, deleteStrategyBlockEdge, getAttributeFields, getAttributeTickers, updateTickers, updateWorkspaceEdge, getNodeFromWorkspace, getLimitPriceFunction, updateWorkspace, getCurrentDeposit, updateCurrentDeposit, getBranch, activateStrategy, deactivateStrategy, createStickerNode, getStickers, updateStickerNode, deleteStickerNode, getFunctionsCategories, getActiveStatusStrategy, };
